<template>
  <div
    class="btn-base-button"
    :data-pptr="buttonName"
    :class="customClass"
    @click="$emit('click')"
  >
    <div class="btn-img">
      <img :src="logo" />
    </div>
    <div class="btn-label">
      <div v-if="label" class="text-md font-bold label">
        {{ label }}
        <span v-if="isFull" class="text-red ml-1">(เต็ม)</span>
      </div>
      <div class="text-left deli-detail">
        {{ desc }}
      </div>
    </div>
    <!-- <v-col cols="12">
      <v-row v-bind="{ justify: 'center', 'no-gutters': true }">
        <v-col cols="2" class="align-self-center">
          <v-row v-if="logo" no-gutters>
            <v-col
              cols="12"
              class="d-flex align-items-center justify-end pr-2"
            >
              <div class="d-felx justify-end">
                <img
                  width="40px"
                  height="40px"
                  class="w-full fit-contain"
                  :src="logo"
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="10" class="align-self-center">
          <v-row no-gutters>
            <v-row no-gutters>
              <v-col
                v-if="label"
                cols="12"
                class="text-left text-md font-bold align-self-center"
              >
                {{ label }}
              </v-col>
              <v-col v-if="desc" cols="12" class="align-self-center">
                <div class="text-left deli-detail">
                  {{ desc }}
                </div>
              </v-col>
            </v-row>
          </v-row>
        </v-col>
      </v-row>
    </v-col> -->
  </div>
</template>

<script>
export default {
  computed: {
    customClass() {
      if (this.disabled) {
        return {
          disabled: this.disabled,
          'text-red': this.textRed,
        }
      }
      return {
        select: this.selected,
        'has-error': this.hasError,
        'text-red': this.textRed,
      }
    },
  },

  props: {
    buttonName: {
      type: String,
      default: '',
    },

    logo: {
      type: Object,
      default: null,
    },

    label: {
      type: String,
      default: '',
    },

    desc: {
      type: String,
      default: '',
    },

    selected: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    hasError: {
      type: Boolean,
      default: false,
    },

    textRed: {
      type: Boolean,
      default: false,
    },

    isFull: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="stylus">

.btn-base-button
    display flex
    align-items center
    width 100%
    height 100%
    min-height: 77px
    background white
    cursor pointer
    border-radius: 8px;
    // border 1px solid $color-bnn-border-dark-btn
    box-shadow: 0 0 0 1px $color-bnn-gray-light;
    flex: 1 1
    gap 1rem
    padding 0 1rem
    transition: 0.2s ease-in-out;
    @media (hover: hover) and (pointer: fine)
        &:hover:not(.disabled)
          border-color: transparent;
          box-shadow: 0 0 0 2px $color-bnn-border;
          transition: 0.2s ease-in-out;
          // border-color: $color-dark-gray;


.btn-base-button.select
    box-shadow: 0 0 0 2px $color-bnn;
    // border-color: $color-bnn;
    //  box-shadow: 0px 2px 3px #00000029;
.btn-base-button.has-error
    border-color: red
.btn-base-button.disabled
    opacity 0.6
    cursor not-allowed
.deli-detail
    font-size 0.725rem
    @media small
       font-size 0.8rem

.label
  width 100%
  display flex
  text-align: left
  align-self: center

.btn-label
    display: flex
    justify-content: flex-start
    flex-direction: column
    align-items: flex-start

.btn-img
  width: 50px
  height: 50px
  padding: 4px
  img
    width: 100%
    height: 100%
    object-fit: contain
    object-position: center
</style>
