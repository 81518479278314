<template>
  <div>
    <!-- Product -->

    <div class="product-card">
      <div class="preview">
        <img :src="selectSKU.image_url" class="img" />
        <div class="product-main-layout">
          <div class="desc-main">
            <div class="product-name">{{ selectSKU.name }}</div>
            <div class="product-sku">SKU: {{ selectSKU.sku }}</div>
          </div>
          <div class="price-main">
            <div class="price-text margin-top-0-5">
              ฿{{ selectSKU.price | currency }}.-
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-row v-bind="{ justify: 'between' }" class="is-desktop">
      <v-col>
        <h3 class="text-main-product">{{ selectSKU.name }}</h3>
        <div class="text-main-product">
          SKU:
          <span>{{ selectSKU.sku }}</span>
        </div>
      </v-col>
      <v-col class="text-right text-main-product price-text">
        ฿{{ selectSKU.price | currency2 }}
      </v-col>
      <v-col cols="12">
        <CheckoutProductItems
          :data="[
            {
              imageUrl: selectSKU.image_url,
              name: selectSKU.name,
              price_ship: selectSKU.price,
            },
            ...bundleList,
          ]"
          @remove="onRemoveBundleAndAdditional(item)"
        />
      </v-col>
    </v-row>

    <!-- <hr
      v-if="bundleList.length > 0 || isCanAddAppleCare"
      class="my-3 gray"
    />

    <v-row
      v-if="bundleList.length > 0 || additionalList.length > 0"
      v-bind="{ justify: 'between', align: 'center' }"
    >
      <v-col><h2>สินค้าซื้อคู่กัน</h2></v-col>

      <v-col cols="12">
        <CheckoutProductItems :data="bundleList" />
      </v-col>
    </v-row> -->

    <!-- <v-row
      v-for="(item, index) in bundleList"
      :key="index"
      v-bind="{ justify: 'between' }"
      class="bundle-list"
    >
      <v-col cols="8">
        <div class="sku-product">
          <img :src="item.imageUrl" />
          <div>{{ item.name }}</div>
        </div>
      </v-col>
      <template v-if="+item.price_srp === 0">
        <v-col class="price-bundle-text font-bold free">
          <div>ฟรี</div>
        </v-col>
      </template>
      <template v-else>
        <v-col cols="4" class="price-bundle-text font-bold">
          <div v-if="isDelivery">
            ฿{{ item.price_ship | currency2 }}
            <div
              @click="onRemoveBundleAndAdditional(item)"
              class="text-remove"
            >
              Remove
            </div>
          </div>
          <div v-else-if="isStore">
            ฿{{ item.price_store | currency2 }}
            <div
              @click="onRemoveBundleAndAdditional(item)"
              class="text-remove"
            >
              Remove
            </div>
          </div>
          <div
            v-if="isDelivery && item.price_ship !== item.price_srp"
            class="text-line-through normal-price"
          >
            ฿{{ item.price_srp | currency2 }}
          </div>
          <div
            v-else-if="isStore && item.price_store !== item.price_srp"
            class="text-line-through normal-price"
          >
            ฿{{ item.price_srp | currency2 }}
          </div>
        </v-col>
      </template>
    </v-row> -->

    <!-- <hr v-if="additionalList.length > 0" class="my-3 gray" />
    <v-row
      v-if="additionalList.length > 0"
       v-bind="{ justify: 'between', align: 'center' }"
    >
      <v-col><h2>อุปกรณ์เสริมที่ซื้อคู่กัน</h2></v-col>
    </v-row> -->
    <!-- <v-row
      v-for="item in additionalList"
      :key="item.sku"
      v-bind="{ justify: 'between' }"
      class="bundle-list"
    >
      <v-col cols="8">
        <div class="sku-product">
          <img :src="item.imageUrl" />
          <div>{{ item.name }}</div>
        </div>
      </v-col>
      <v-col cols="4" class="price-bundle-text font-bold">
        <div v-if="isDelivery">
          ฿{{ item.price_ship | currency2 }}
          <div
            @click="onRemoveBundleAndAdditional(item)"
            class="text-remove"
          >
            Remove
          </div>
        </div>
        <div v-else-if="isStore">
          ฿{{ item.price_store | currency2 }}
          <div
            @click="onRemoveBundleAndAdditional(item)"
            class="text-remove"
          >
            Remove
          </div>
        </div>
        <div
          v-if="isDelivery && item.price_ship !== item.price_srp"
          class="text-line-through normal-price"
        >
          ฿{{ item.price_srp | currency2 }}
        </div>
        <div
          v-else-if="isStore && item.price_store !== item.price_srp"
          class="text-line-through normal-price"
        >
          ฿{{ item.price_srp | currency2 }}
        </div>
      </v-col>
    </v-row> -->

    <!-- Apple Care -->
    <v-row
      v-if="isCanAddAppleCare"
      v-bind="{ justify: 'between', align: 'center' }"
    >
      <v-col><h2>เพิ่มความคุ้มครอง</h2></v-col>
    </v-row>

    <AppleCare
      v-if="isCanAddAppleCare"
      @change="checkSummary"
      is-card
    />

    <hr v-if="isShowDelivery" class="my-3 gray" />
    <v-row
      v-if="isShowDelivery"
      v-bind="{ justify: 'between', align: 'center' }"
    >
      <v-col><h2>การจัดส่ง</h2></v-col>
    </v-row>
    <template v-if="isShowDelivery">
      <v-row
        v-for="(item, index) in deliveryList"
        :key="index"
        v-bind="{ justify: 'between' }"
        class="bundle-list"
      >
        <v-col cols="8">
          <div class="sku-product">
            <img :src="item.image_url" />
            <div>{{ item.name }}</div>
          </div>
        </v-col>
        <v-col cols="4" class="price-bundle-text font-bold">
          <div v-if="isDelivery">
            ฿{{ item.price_ship | currency2 }}
          </div>
          <div v-else-if="isStore">
            ฿{{ item.price_store | currency2 }}
          </div>
          <div
            v-if="isDelivery && item.price_ship !== item.price_srp"
            class="text-line-through normal-price"
          >
            ฿{{ item.price_srp | currency2 }}
          </div>
          <div
            v-else-if="isStore && item.price_store !== item.price_srp"
            class="text-line-through normal-price"
          >
            ฿{{ item.price_srp | currency2 }}
          </div>
        </v-col>
      </v-row>
    </template>

    <div v-if="isShowCoupon" class="coupon-code">
      <div class="input-coupon-code">
        <v-text-field
          v-model="form.couponCode"
          :color="colorBnn['color-bnn']"
          :readonly="validCoupon ? true : false"
          :clearable="
            ($v.form.couponCode.$error || coupon.isError) &&
              !freebieListByCouponModal
          "
          :class="{ 'hide-text': validCoupon }"
          :error="
            ($v.form.couponCode.$error || coupon.isError) &&
              !freebieListByCouponModal
          "
          :placeholder="couponCodePlaceholder"
          type="text"
          ref="coupon_code"
          background-color="white"
          data-pptr="couponCode"
          class="input-customer text-sm mt-2"
          @click:clear="clearCoupon"
        >
          <template v-slot:append>
            <v-icon v-if="validCoupon" color="green">
              mdi-check-bold
            </v-icon>
          </template>
          <template v-slot:prepend-inner>
            <v-chip
              v-if="validCoupon"
              class="ma-1 font-bold"
              :class="{ 'text-white': !isBnn }"
              :color="colorBnn['color-coupon']"
            >
              {{ form.couponCode }}
              <span class="ml-2"
                ><v-icon @click.stop="clearCoupon" small
                  >mdi-close</v-icon
                ></span
              >
            </v-chip>
          </template>
        </v-text-field>
      </div>
      <div class="use-code">
        <Button
          v-if="validCoupon"
          @click="clearCoupon"
          block
          depressed
          color="color-bnn-gray-light-2
        
        "
          title="ยกเลิก"
        />

        <Button
          v-else
          @click="checkValidateCoupon"
          block
          depressed
          color="color-bnn"
          title="ใช้โค้ด"
        />
      </div>
    </div>
    <div v-if="msgError" class="coupon-msg-error">
      <span>{{ msgError }}</span>
    </div>

    <div
      v-if="validCoupon && couponDiscount > 0"
      class="coupon-msg-success"
    >
      <span>รับส่วนลด {{ couponDiscount | currency2 }} บาท</span>
    </div>
    <hr class="my-3 gray" />

    <!-- <v-row  v-bind="{ justify: 'between', align: 'center' }">
      <v-col><h2>ราคาทั้งหมด</h2></v-col>
    </v-row> -->
    <!-- Deposit -->
    <template v-if="isDeposit">
      <!-- <v-row  v-bind="{ justify: 'between', align: 'center' }">
        <v-col>
          <h3>ยอดสุทธิ</h3>
        </v-col>
        <v-col class="text-right">
          <h3>฿{{ beforeDiscount | currency2 }}</h3>
        </v-col>
      </v-row>

      <hr class="my-3 gray" /> -->

      <v-row v-bind="{ justify: 'between', align: 'center' }">
        <v-col>
          <h3>(มัดจำ) ค่าเครื่อง</h3>
        </v-col>
        <v-col class="text-right">
          <h3 class="price-summary-text">
            ฿{{ productPriceDeposit | currency2 }}
          </h3>
        </v-col>
      </v-row>
      <v-row
        v-if="bundleAmount > 0"
        v-bind="{ justify: 'between', align: 'center' }"
      >
        <v-col>
          <h3>สินค้าอุปกรณ์เสริม</h3>
        </v-col>
        <v-col class="text-right">
          <h3 class="price-summary-text">
            ฿{{ bundleAmount | currency2 }}
          </h3>
        </v-col>
      </v-row>

      <hr class="my-3 gray" />

      <template v-if="validCoupon && couponDiscount">
        <v-row v-bind="{ justify: 'between', align: 'center' }">
          <v-col>
            <h3>{{ titleDiscount }}</h3>
          </v-col>
          <v-col class="text-right">
            <h3>- ฿{{ couponDiscount | currency2 }}</h3>
          </v-col>
        </v-row>
      </template>

      <template v-if="onTopDiscount > 0">
        <v-row v-bind="{ justify: 'between', align: 'center' }">
          <v-col>
            <h4>ส่วนลดเพิ่มเติม</h4>
          </v-col>
          <v-col class="text-right text-discount">
            <h4>- ฿{{ onTopDiscount | currency2 }}</h4>
          </v-col>
        </v-row>
      </template>

      <template v-if="specialDiscount > 0">
        <v-row v-bind="{ justify: 'between', align: 'center' }">
          <v-col>
            <h3>{{ titleSpecialDiscount }}</h3>
          </v-col>
          <v-col class="text-right">
            <h3>- ฿{{ specialDiscount | currency2 }}</h3>
          </v-col>
        </v-row>
      </template>

      <!-- <v-row  v-bind="{ justify: 'between', align: 'center' }">
        <v-col>
          <h3>สินค้าอุปกรณ์เสริม</h3>
        </v-col>
        <v-col class="text-right">
          <h3 class="price-summary-text">
            ฿{{ bundleAmount | currency2 }}
          </h3>
        </v-col>
      </v-row> -->

      <hr
        v-if="validCoupon && (couponDiscount || onTopDiscount)"
        class="my-3 gray"
      />

      <!-- <v-row  v-bind="{ justify: 'between', align: 'center' }">
        <v-col>
          <h3>ยอดชำระส่วนที่เหลือทั้งหมด</h3>
          <div v-if="depositMsg && isDelivery">
            <span class="text-sm text-red font-bold">{{
              depositMsg
            }}</span>
          </div>
        </v-col>
        <v-col class="text-right">
          <h3 class="price-summary-text">
            ฿{{ remainAmount | currency2 }}
          </h3>
        </v-col>
      </v-row>

      <hr class="my-3 gray" /> -->

      <v-row v-bind="{ justify: 'between', align: 'center' }">
        <v-col class="text-nowrap">
          <h3>ยอดรวมสุทธิ</h3>
          <div v-if="depositMsg && isDelivery">
            <span class="text-sm text-red font-bold text-deposit">{{
              depositMsg
            }}</span>
          </div>
          <div v-else>
            <span class="text-sm text-red font-bold text-deposit">
              ยอดคงเหลือที่ต้องชำระ
            </span>
          </div>
        </v-col>
        <v-col class="text-right">
          <h3 class="price-summary-text text-color-bnn">
            ฿{{ netPriceSummary | currency2 }}
          </h3>
          <div>
            <h3 class="text-sm text-red">
              ฿{{ remainAmount | currency2 }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </template>
    <!-- Eืnd Deposit -->
    <template v-else>
      <!-- Full Payment -->
      <!-- <v-row  v-bind="{ justify: 'between', align: 'center' }">
        <v-col>
          <h3>ยอดสุทธิ</h3>
        </v-col>
        <v-col class="text-right">
          <h3>฿{{ beforeDiscount | currency2 }}</h3>
        </v-col>
      </v-row>

      <hr class="my-3 gray" /> -->
      <v-row v-bind="{ justify: 'between', align: 'center' }">
        <v-col>
          <h3>ยอดชำระค่าเครื่อง</h3>
        </v-col>
        <v-col class="text-right">
          <h3>฿{{ mainProductPrice | currency2 }}</h3>
        </v-col>
      </v-row>
      <v-row
        v-if="bundleAmount > 0"
        v-bind="{ justify: 'between', align: 'center' }"
      >
        <v-col>
          <h3>สินค้าอุปกรณ์เสริม</h3>
        </v-col>
        <v-col class="text-right">
          <h3>฿{{ bundleAmount | currency2 }}</h3>
        </v-col>
      </v-row>

      <hr class="my-3 gray" />

      <template v-if="validCoupon && couponDiscount">
        <v-row v-bind="{ justify: 'between', align: 'center' }">
          <v-col>
            <h3>{{ titleDiscount }}</h3>
          </v-col>
          <v-col class="text-right">
            <h3>- ฿{{ couponDiscount | currency2 }}</h3>
          </v-col>
        </v-row>
      </template>

      <template v-if="onTopDiscount > 0">
        <v-row v-bind="{ justify: 'between', align: 'center' }">
          <v-col>
            <h4>ส่วนลดเพิ่มเติม</h4>
          </v-col>
          <v-col class="text-right">
            <h4>- ฿{{ onTopDiscount | currency2 }}</h4>
          </v-col>
        </v-row>
      </template>

      <template v-if="specialDiscount > 0">
        <v-row v-bind="{ justify: 'between', align: 'center' }">
          <v-col>
            <h3>{{ titleSpecialDiscount }}</h3>
          </v-col>
          <v-col class="text-right">
            <h3>- ฿{{ specialDiscount | currency2 }}</h3>
          </v-col>
        </v-row>
      </template>

      <hr
        v-if="validCoupon && (couponDiscount || onTopDiscount)"
        class="my-3 gray"
      />

      <v-row v-bind="{ justify: 'between', align: 'center' }">
        <v-col>
          <h3>ยอดรวมสุทธิ</h3>
        </v-col>
        <v-col class="text-right">
          <h3 class="price-summary-text text-color-bnn">
            ฿{{ netPriceSummary | currency2 }}
          </h3>
        </v-col>
      </v-row>
      <!--End Full Payment -->
    </template>

    <v-row v-if="imageBelow" class="my-2">
      <v-col>
        <img
          @click="openLinkBelow"
          class="img-below"
          :class="customClassLinkBelow"
          :src="imageBelow"
        />
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-col>
        <div>
          <span class="payment-type-text">
            ({{ branchTypeText }})
          </span>
        </div>
        <div v-if="priceDeposit && isDeposit">
          ชำระยอดมัดจำ
          <span class="text-red">
            ฿{{ priceDeposit | currency2 }}
          </span>
        </div>
      </v-col>
    </v-row> -->
    <!-- <CartSummary ref="cartSummary" /> -->

    <!-- Freebies modal from coupon code -->
    <template v-if="freebieListByCoupon.length > 0">
      <ModalSelectFreebie
        ref="modalSelectFreebie"
        :data="freebieListByCoupon"
        @opened="handleModalFreebieIsOpen"
        @select="onClickSelectFreebie"
      />
    </template>
  </div>
</template>

<script>
import router from '@/router'
import { mapState, mapGetters } from 'vuex'
import Button from '@/components/bnn/Button'
import colorBnn from '@/config/color'
import { validationMixin } from 'vuelidate'
import { maxLength } from 'vuelidate/lib/validators'
import branchTypes from '@/config/branchTypes'
import numeral from 'numeral'
import cartSummary from '@/mixins/cartSummary'
import config from '@/config/index'
const { showFreeBie } = config
import isBnn from '@/config/isBnn'
import ModalSelectFreebie from '@/components/bnn/ModalSelectFreebie'
import AppleCare from '@/components/bnn/AppleCare'

export default {
  mixins: [validationMixin, cartSummary],

  computed: {
    ...mapState({
      selectSKU: state => state.model.selectSKU,
      branchType: state => state.branchStore.branchType,
      coupon: state => state.cart.coupon,
      cartDelivery: state => state.cart.delivery,
      cartSummaryData: state => state.cart.cartSummaryData,
      paymentTypeData: state => state.paymentType.data,
      paymentId: state => state.paymentType.paymentId,
      selectBundleDelivery: state => state?.bundle.delivery,
      campaign: state => state.campaign.current,
      selects: state => state.bundle.selects,
    }),

    ...mapGetters({
      bundles: 'model/getBundles',
      additionalProduct: 'model/getAdditionalProduct',
      appleCare: 'model/getAppleCare',
      cartItems: 'cart/cartItems',
      productItem: 'cart/productItem',
      priceSummary: 'cart/priceSummary',
      isSoldOut: 'model/getSoldOut',
      bundleItems: 'cart/bundleItems',
      discountOnTop: 'cart/discountOnTop',
      deliveryItems: 'cart/deliveryItems',
      getBundleDelivery: 'model/getBundleDelivery',
      accessoryBundles: 'model/getAccessoryBundles',
      // getFreebieHandraiser
      handraiserFreebies: 'model/getHandraiserFreebies' ?? [],
    }),

    forceBuyBundle() {
      return this.selectSKU?.force_bundle === 1
    },

    imageBelow() {
      if (this.isDeposit) {
        return this.campaign?.data?.img_below_cart_dep ?? null
      }
      return this.campaign?.data?.img_below_cart_full ?? null
    },

    linkBelow() {
      return this.campaign?.data?.link_below_cart ?? null
    },

    customClassLinkBelow() {
      return {
        pointer: this.linkBelow,
      }
    },

    couponCodeParam() {
      return router.currentRoute.query.v
    },

    forceCoupon() {
      return (
        this.campaign?.data?.force_coupon ?? ''
        // this.couponCodeParam ??
      )
    },

    depositMsg() {
      return this.campaign?.data?.deposit_msg ?? ''
    },

    isDelivery() {
      return (
        this.cartDelivery === 'delivery' || this.cartDelivery === true
      )
    },

    isStore() {
      return this.cartDelivery === 'store'
    },

    isCanAddAppleCare() {
      return this.appleCare && this.appleCare.active && this.isDeposit
    },

    bundleList() {
      const cartItems =
        this.cartItems.filter(item => item.type === 'bundle') || []

      if (!showFreeBie) {
        return cartItems?.filter(item => !item?.isFreeBie)
      }
      return cartItems
    },

    freebieListByCoupon() {
      const freebieList = this.coupon?.data ?? []
      const freebieListByCoupon = this.accessoryBundles?.filter(
        item => freebieList.includes(item.sku),
      )
      return freebieListByCoupon ?? []
    },

    additionalList() {
      return (
        this.cartItems.filter(
          item => item.type === 'additional_product',
        ) || []
      )
    },

    currentDeliverySku() {
      return Object.keys(this?.selectBundleDelivery)?.[0] ?? null
    },

    deliveryList() {
      if (this.currentDeliverySku)
        return this?.getBundleDelivery?.filter(
          item => item?.sku === this?.currentDeliverySku,
        )
      return []
    },

    isShowDelivery() {
      return (
        this?.currentDeliverySku !== 'NOITEC00000001' &&
        this.deliveryList?.length > 0
      )
    },

    branchTypeText() {
      if (
        this.branchTypes.BRANCH_TYPE_FULL_PAYMENT === this.branchType
      ) {
        return 'ชำระเงินเต็มจำนวน'
      } else {
        return 'ชำระเงินมัดจำ'
      }
    },

    isDeposit() {
      return this.branchType === this.branchTypes.BRANCH_TYPE_DEPOSIT
    },

    priceDeposit() {
      return this.selectSKU?.price_deposit
    },

    depositTitle() {
      if (
        this.branchTypes.BRANCH_TYPE_FULL_PAYMENT === this.branchType
      ) {
        return null
      }
      if (this.selectSKU?.price_deposit) {
        return `ชำระยอดมัดจำ ฿${numeral(
          this.selectSKU?.price_deposit ?? 0,
        ).format('0,0')}.-`
      }
      return null
    },

    validCoupon() {
      return !this.coupon.isError && this.coupon.code
    },

    msgError() {
      if (this.coupon.status === 422) {
        return !this.freebieListByCouponModal
          ? this.coupon.message
          : ''
      } else if (this.coupon.isError) {
        return 'โค้ดไม่ถูกต้อง'
      }
      return ''
    },

    isShowCoupon() {
      return (
        this.branchTypes.BRANCH_TYPE_FULL_PAYMENT ===
          this.branchType ||
        this.branchTypes.BRANCH_TYPE_DEPOSIT === this.branchType
      )
    },

    beforeDiscount() {
      return this.cartSummaryData?.before_discount ?? 0
    },

    couponDiscount() {
      return this.cartSummaryData?.coupon_discount ?? 0
    },

    afterCouponDiscount() {
      return this.cartSummaryData?.after_coupon_discount ?? 0
    },

    onTopDiscount() {
      return this.cartSummaryData?.on_top_discount ?? 0
    },

    specialDiscount() {
      return this.cartSummaryData?.special_discount ?? 0
    },

    summaryDiscount() {
      return this.cartSummaryData?.summary_discount ?? 0
    },

    onTopDiscountPercent() {
      return this.cartSummaryData?.on_top_discount_percent ?? 0
    },

    netPriceSummary() {
      return this.cartSummaryData?.total_price ?? 0
    },

    mainProductPrice() {
      return this.cartSummaryData?.main_product_price ?? 0
    },

    bundleAmount() {
      return this.cartSummaryData?.bundle_amount ?? 0
    },

    productPriceDeposit() {
      return this.cartSummaryData?.product_price_deposit ?? 0
    },

    titleDiscount() {
      if (this.cartSummaryData?.is_discount_bundle) {
        return 'ส่วนลดสินค้าอุปกรณ์เสริม'
      } else if (this.cartSummaryData?.is_handraiser_discount) {
        return 'ส่วนลดจากโปรโมชั่น'
      }
      //   else if (this.cartSummaryData?.is_handraiser_discount) {
      //     return 'ส่วนลดจากโปรโมชั่น'
      //   }
      return 'ส่วนลดค่าเครื่อง'
    },

    finalPrice() {
      // Notes: Previous code is cartSummaryData.final_price
      return this.cartSummaryData?.final_price_original ?? 0
    },

    remainAmount() {
      return this.cartSummaryData?.remain_amount ?? 0
    },

    isSamsungHandraiser() {
      return (
        this.campaign?.data?.handraiser &&
        this.campaign?.data?.handraiser === 'samsung'
      )
    },

    titleSpecialDiscount() {
      return (
        this.cartSummaryData?.special_discount_title ?? 'ส่วนลดพิเศษ'
      )
    },

    couponCodePlaceholder() {
      return this.isSamsungHandraiser
        ? 'ใช้ Handraiser Code ที่นี่'
        : 'กรอกโค้ดส่วนลด'
    },

    decryptedData() {
      return this.$store.getters['user/getData']
    },
  },

  data() {
    return {
      colorBnn,
      isBnn,
      branchTypes,
      showFreeBie,
      form: {
        couponCode: '',
      },
      formCheckSummary: {
        first_name: null,
        last_name: null,
        phone: null,
        email: null,
        address: null,
        sub_district: null,
        district: null,
        province: null,
        postcode: null,
        // Notes: Hide default value from local storage
        // first_name: localStorage.getItem('firstName') || null,
        // last_name: localStorage.getItem('lastName') || null,
        // phone: localStorage.getItem('phoneNumber') || null,
        // email: localStorage.getItem('email') || null,
        // address: localStorage.getItem('address') || null,
        // sub_district: localStorage.getItem('sub_district') || null,
        // district: localStorage.getItem('district') || null,
        // province: localStorage.getItem('province') || null,
        // postcode: localStorage.getItem('postcode') || null,
        product_id: '',
        bundle_products: [],
        delivery: false,
        is_delivery: false,
      },
      freebieListByCouponModal: false,
    }
  },

  validations: {
    form: { couponCode: { maxLength: maxLength(5) } },
  },

  components: {
    Button,
    ModalSelectFreebie,
    AppleCare,

    CheckoutProductItems: () =>
      import('@/components/studio7/CheckoutProductItems'),
  },

  async mounted() {
    const paymentId =
      this.paymentId ||
      this.paymentTypeData?.filter(i =>
        this.isDeposit
          ? i.type === 'deposit'
          : this.$route.query.t
          ? i.type === 'pay-at-store'
          : i.type !== 'deposit',
      )?.[0]?.id

    this.$store.commit('paymentType/setPaymentId', paymentId)

    if (this.forceCoupon) {
      this.form.couponCode = this.forceCoupon
      const payload = {
        productId: this.selectSKU.id,
        couponCode: this.form.couponCode,
        bundleItems: this.bundleItems,
        paymentTypeId: paymentId,
      }

      await this.$store.dispatch('cart/checkCouponCode', payload)

      if (this.coupon.isError) {
        this.form.couponCode = ''
        this.$store.commit('cart/resetCouponCode')
      }
    }

    // Notes: Prepare form check summary
    this.prepareFormCheckSummary()

    this.checkSummary()
  },

  methods: {
    prepareFormCheckSummary() {
      this.$store.dispatch('user/fetchData')

      this.formCheckSummary = {
        ...this.formCheckSummary,
        first_name: this.decryptedData?.first_name || null,
        last_name: this.decryptedData?.last_name || null,
        phone: this.decryptedData?.phone || null,
        email: this.decryptedData?.email || null,
        address: this.decryptedData?.address || null,
        sub_district: this.decryptedData?.sub_district || null,
        district: this.decryptedData?.district || null,
        province: this.decryptedData?.province || null,
        postcode: this.decryptedData?.postcode || null,
      }
    },
    onRemoveBundleAndAdditional(item) {
      this.$store.dispatch('bundle/toggle', item)
      this.$forceUpdate()
      this.checkValidateCoupon()
      this.checkSummary()
    },

    async checkValidateCoupon() {
      if (this.form.couponCode.trim()) {
        const payload = {
          productId: this.selectSKU.id,
          couponCode: this.form.couponCode,
          bundleItems: this.bundleItems,
          paymentTypeId: this.formCheckSummary.payment_type_id,
        }

        await this.$store.dispatch('cart/checkCouponCode', payload)

        if (this.coupon.autoAddToCart) {
          this.HandleFreebieWithCoupon('add')
        }

        this.checkSummary()

        // Notes: Hide modal freebie
        // if (this.coupon.data.length > 0) {
        //   this.$refs.modalSelectFreebie.open()
        // }
      }
    },

    openLinkBelow() {
      if (this.linkBelow) {
        window.open(this.linkBelow, '_blank')
      }
    },

    async clearCoupon() {
      this.couponCode = ''
      this.form.couponCode = ''
      await this.HandleFreebieWithCoupon('remove')
      this.$store.commit('cart/resetCouponCode')
      this.checkSummary()
    },

    onClickSelectFreebie(item) {
      if (item) {
        this.$store.dispatch('bundle/add', item)
        this.checkValidateCoupon()
        this.checkSummary()
      } else {
        this.checkSummary()
      }
    },

    handleModalFreebieIsOpen(value) {
      this.freebieListByCouponModal = value
    },

    HandleFreebieWithCoupon(key) {
      if (this.coupon.autoAddToCart) {
        switch (key) {
          case 'remove':
            this.bundleItems.find(item => {
              if (item.sku === this.coupon.autoAddToCart) {
                this.$store.dispatch('bundle/remove', item)
              }
            })
            break

          default:
            this.handraiserFreebies?.find(item => {
              if (item.sku === this.coupon.autoAddToCart) {
                this.$store.dispatch('bundle/add', item)
              }
            })
            break
        }
      }
    },
  },
}
</script>

<style lang="stylus" scoped>

.coupon-code
    display: flex
    justify-content: center
    align-items: center
    gap: 0 16px
    .input-coupon-code
        width:70%
    .use-code
        width: 30%

.bundle-list
    font-size: 0.95rem

.text-main-product
    font-size: 0.975rem !important
    font-weight: normal

.price-text
    font-size: 1.15rem
    color:  $color-text-price
    text-align: right


.price-bundle-text
    // color:  $color-text-price
    text-align: right
    display: flex
    flex-direction: column
    justify-content: flex-start
    white-space: nowrap

.price-bundle-text.free
	color: #4CAF50;

.price-summary-text
    color:  $color-text-price

.text-color-bnn
    color: $color-bnn

.payment-type-text
    color: $color-bnn-orange

.text-red
    color: $color-bnn-red-2

.sku-product
  display: flex
  gap 0 16px
  align-items: flex-start
  img
    width: 48px
    height: 48px
    object-fit: contain

.coupon-msg-error
  color: $color-bnn-red
  margin-top: -1rem
  font-size: 1rem

.normal-price
  color: $color-bnn-gray-medium-2
  font-size: 0.85rem
  text-align: right

.text-discount
  color: $color-bnn-red

::v-deep .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea
  display none

.font-bold
  font-weight: bold

.hide-text
  font-size: 0

.coupon-msg-success
  color: $color-bnn-gray-medium-2
  font-size: 1rem
  font-weight: bold
  margin-top: -1rem

.text-deposit
  color: $color-bnn-border-dark-btn

.img-below
  width: 100%
  object-fit: contain
.pointer
  cursor: pointer

.text-nowrap
  white-space: nowrap

.product-main-layout
  display: flex
  justify-content: space-between
  width 100%
  @media small
    flex-direction: row
    flex-wrap: wrap
  .desc-main
    width 50%
    display: flex
    flex-direction: column
    justify-content: flex-start
    height 100%
    @media small
      justify-content: flex-start
      width 100%
      height auto
    @media tablet
      justify-content: flex-start
    .product-name
                color: $color-bnn-gray-medium
                font-weight: bold
                font-size: 1rem
                margin-top: 0.5rem
    .product-sku
                color: $color-bnn-gray-medium-2
  .price-main
    width 50%
    display: flex
    flex-direction: column
    justify-content: flex-start
    height 100%
    text-align: right
    @media small
      width 100%
      align-items: flex-start
    @media tablet
      justify-content: space-between
    .price-text, .price-text-2
          font-size: 1rem
          // color:  $color-text-price
          text-align: right
          font-weight: bold

    .price-text-2
          //  color: $color-bnn !important


.product-card
    display: none !important
    @media tablet
      display: block !important

.product-card

    // background: white 0% 0% no-repeat padding-box;
    border-radius: 8px;
    display: flex
    justify-content: space-between
    flex: 1 1
    padding: 1.25rem 0rem
    margin-top: 0px
    flex-direction: column
    @media screen and (max-width: 768px)
      padding: 1rem 0.5rem 0 0.5rem
      font-size: 0.85rem
    .preview
        width: 100%
        display: flex
        justify-content: flex-start
        gap: 16px 16px
        margin: 1rem 0
        .img
            width: 100px
            height: 100px
            min-width: 100px
            max-width: 100px
            min-height: 100px
            max-height: 100px
            // height: 100%
            object-fit: contain
        .desc
            display: flex
            flex-direction: column
            justify-content: flex-start
            width: 100%
            .product-name
                color: $color-bnn-gray-medium
                font-weight: bold
                margin-top: 0.5rem
            .product-sku
                color: $color-bnn-gray-medium-2

    .price
        width: 100%
        display: flex
        justify-content: flex-start
        flex-direction: column
        align-items: flex-end
        .price-text
            font-size: 1rem
            color:  $color-text-price
            text-align: right
            @media screen and (max-width: 768px)
              font-size: 0.9rem
              margin-top: 0.5rem
.action-layout
  width: 100%
  display: flex
  justify-content: flex-end
  margin: 1.5rem 0
  .action
        width 50%
        display: grid
        gap: 16px
        grid-template-columns: repeat(2, 1fr)

.summary
    display: flex
    justify-content: space-between
    margin: 1rem 0
    .count-number
      width: 50%
      color: $color-bnn-gray-medium-2
    .price-text
      width 50%
      display: flex
      flex-wrap: nowrap
      flex-direction: row
      justify-content: flex-end
      .price
        color: $color-bnn-red-2
        font-weight: bold
        width auto
        margin-left: 0.2rem

.justify-between
    justify-content: space-between !important

.margin-top-0-5
    margin-top: 0.5rem

.drop-full-payment
  font-size: 0.875rem !important
  color: $color-bnn-gray-medium-2

.is-desktop
  @media tablet
    display: none !important
.text-remove
  color: $color-blue
  cursor: pointer
  font-weight: 200

.text-white
  color: #fff !important
</style>
