<template>
  <v-dialog v-model="dialog" persistent max-width="700">
    <v-card>
      <div class="header-modal">
        <span class="header-modal-title">
          {{ title }}
        </span>
        <span class="header-modal-close" @click="close">
          <v-icon>mdi-close</v-icon>
        </span>
      </div>
      <div class="body-modal">
        <template v-if="data.length > 0">
          <BundleSwiper
            key="bundle"
            :data="data"
            @click="onClickSelectBundle"
            is-freebies-by-coupon
          />
        </template>
        <template v-else>
          <div class="empty-data">
            <span>ไม่พบสินค้าแถม</span>
          </div>
        </template>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import BundleSwiper from '@/components/bnn/BundleSwiper'

export default {
  props: {
    title: {
      type: String,
      default: 'คุณได้รับโปรโมชั่นพิเศษ! โปรดเลือกของแถม',
    },
    data: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    BundleSwiper,
  },

  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    open() {
      this.dialog = true
      this.$emit('opened', this.dialog)
    },

    close() {
      this.dialog = false
      this.$emit('opened', this.dialog)
      this.$emit('select', null)
    },

    onClickSelectBundle(item) {
      this.dialog = false
      this.$emit('select', item)
    },
  },
}
</script>

<style lang="stylus" scoped>

.header-modal
  display: flex
  justify-content: space-between
  width: 100%
  padding: 16px

.body-modal
  padding: 16px

.header-modal-title
  font-size: 16px
  font-weight: bold
  font-family: $font-family-base !important
  align-self: center


.header-modal-close
  cursor: pointer
  color: $color-bnn-gray-light
  align-self: center

.empty-data
	text-align: center
	color: $color-bnn-gray-medium-2
	padding: 48px 0
</style>
