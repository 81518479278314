/**
 * Enum for Browser breakpoint
 * @readonly
 * @enum {String}
 */
export default Object.freeze({
  model: [
    {
      brand: 'SAMSUNG',
      model: 'S24 ULTRA',
    },
    {
      brand: 'SAMSUNG',
      model: 'S24+',
    },
    {
      brand: 'SAMSUNG',
      model: 'S24',
    },
    {
      brand: 'SAMSUNG',
      model: 'S23 ULTRA',
    },
    {
      brand: 'SAMSUNG',
      model: 'S23+',
    },
    {
      brand: 'SAMSUNG',
      model: 'S23',
    },
    {
      brand: 'SAMSUNG',
      model: 'S23 FE',
    },
    {
      brand: 'SAMSUNG',
      model: 'S22 ULTRA',
    },
    {
      brand: 'SAMSUNG',
      model: 'S22+',
    },
    {
      brand: 'SAMSUNG',
      model: 'S22',
    },
    {
      brand: 'SAMSUNG',
      model: 'S21 ULTRA',
    },
    {
      brand: 'SAMSUNG',
      model: 'S21+',
    },
    {
      brand: 'SAMSUNG',
      model: 'S21',
    },
    {
      brand: 'SAMSUNG',
      model: 'S21 FE',
    },
    {
      brand: 'SAMSUNG',
      model: 'S20 ULTRA',
    },
    {
      brand: 'SAMSUNG',
      model: 'S20+',
    },
    {
      brand: 'SAMSUNG',
      model: 'S20',
    },
    {
      brand: 'SAMSUNG',
      model: 'Z FOLD 5',
    },
    {
      brand: 'SAMSUNG',
      model: 'Z FOLD 4',
    },
    {
      brand: 'SAMSUNG',
      model: 'Z FOLD 3',
    },
    {
      brand: 'SAMSUNG',
      model: 'Z FLIP 5',
    },
    {
      brand: 'SAMSUNG',
      model: 'Z FLIP 4',
    },
    {
      brand: 'SAMSUNG',
      model: 'Z FLIP 3',
    },
    {
      brand: 'SAMSUNG',
      model: 'A73',
    },
    {
      brand: 'SAMSUNG',
      model: 'A54',
    },
    {
      brand: 'SAMSUNG',
      model: 'OTHER SAMSUNG',
    },
    {
      brand: 'APPLE',
      model: 'IPHONE 15 PRO MAX',
    },
    {
      brand: 'APPLE',
      model: 'IPHONE 15 PRO',
    },
    {
      brand: 'APPLE',
      model: 'IPHONE 15 PLUS',
    },
    {
      brand: 'APPLE',
      model: 'IPHONE 15 BASE',
    },
    {
      brand: 'APPLE',
      model: 'IPHONE 14 PRO MAX',
    },
    {
      brand: 'APPLE',
      model: 'IPHONE 14 PRO',
    },
    {
      brand: 'APPLE',
      model: 'IPHONE 14PLUS',
    },
    {
      brand: 'APPLE',
      model: 'IPHONE 14 BASE',
    },
    {
      brand: 'APPLE',
      model: 'IPHONE 13 PRO MAX',
    },
    {
      brand: 'APPLE',
      model: 'IPHONE 13 PRO',
    },
    {
      brand: 'APPLE',
      model: 'IPHONE 13 PLUS',
    },
    {
      brand: 'APPLE',
      model: 'IPHONE 13 BASE',
    },
    {
      brand: 'APPLE',
      model: 'IPHONE 12 PRO MAX',
    },
    {
      brand: 'APPLE',
      model: 'IPHONE 12 PRO',
    },
    {
      brand: 'APPLE',
      model: 'IPHONE 12 PLUS',
    },
    {
      brand: 'APPLE',
      model: 'IPHONE 12 BASE',
    },
    {
      brand: 'APPLE',
      model: 'IPHONE 11 PRO MAX',
    },
    {
      brand: 'APPLE',
      model: 'IPHONE 11 PRO',
    },
    {
      brand: 'APPLE',
      model: 'IPHONE 11 PLUS',
    },
    {
      brand: 'APPLE',
      model: 'IPHONE 11 BASE',
    },
    {
      brand: 'APPLE',
      model: 'OTHER IPHONE',
    },
    {
      brand: 'HUAWEI',
      model: 'P50',
    },
    {
      brand: 'HUAWEI',
      model: 'NOVA 10 PRO',
    },
    {
      brand: 'HUAWEI',
      model: 'MATE 50 PRO',
    },
    {
      brand: 'HUAWEI',
      model: 'P50 PRO',
    },
    {
      brand: 'HUAWEI',
      model: 'P50 POCKET',
    },
    {
      brand: 'HUAWEI',
      model: 'NOVA9 SE',
    },
    {
      brand: 'HUAWEI',
      model: 'NOVA9',
    },
    {
      brand: 'HUAWEI',
      model: 'OTHER HUAWEI',
    },
    {
      brand: 'OPPO',
      model: 'RENO 10 PRO+',
    },
    {
      brand: 'OPPO',
      model: 'FIND N 3 FLIP',
    },
    {
      brand: 'OPPO',
      model: 'FIND N 3 FLIP',
    },
    {
      brand: 'OPPO',
      model: 'FIND N 2 FLIP',
    },
    {
      brand: 'OPPO',
      model: 'A98 5G',
    },
    {
      brand: 'OPPO',
      model: 'RENO 10 5G',
    },
    {
      brand: 'OPPO',
      model: 'RENO 10 PRO 5G',
    },
    {
      brand: 'OPPO',
      model: 'RENO 8T 5G',
    },
    {
      brand: 'OPPO',
      model: 'RENO 8 PRO 5G',
    },
    {
      brand: 'OPPO',
      model: 'RENO 8Z 5G',
    },
    {
      brand: 'OPPO',
      model: 'OTHER OPPO',
    },
    {
      brand: 'VIVO',
      model: 'V29 5G',
    },
    {
      brand: 'VIVO',
      model: 'V27 5G',
    },
    {
      brand: 'VIVO',
      model: 'X90 PRO 5G',
    },
    {
      brand: 'VIVO',
      model: 'V29 E 5G',
    },
    {
      brand: 'VIVO',
      model: 'V27 5G',
    },
    {
      brand: 'VIVO',
      model: 'V23 5G',
    },
    {
      brand: 'VIVO',
      model: 'OTHER VIVO',
    },
    {
      brand: 'XIAOMI',
      model: '13 T',
    },
    {
      brand: 'XIAOMI',
      model: '13 T PRO',
    },
    {
      brand: 'XIAOMI',
      model: 'NOTE 12 PRO+',
    },
    {
      brand: 'XIAOMI',
      model: 'NOTE 12 PRO+',
    },
    {
      brand: 'XIAOMI',
      model: '12 T PRO',
    },
    {
      brand: 'XIAOMI',
      model: '12 T',
    },
    {
      brand: 'XIAOMI',
      model: '12 PRO',
    },
    {
      brand: 'XIAOMI',
      model: '12',
    },
    {
      brand: 'XIAOMI',
      model: 'OTHER XIAOMI',
    },
    {
      brand: 'OTHERS',
      model: 'OTHERS',
    },
  ],
  interestItem: [
    'ไม่สนใจ',
    'Galaxy Watch6 40mm',
    'Galaxy Watch6 44mm',
    'Galaxy Watch6 Classic 43mm',
    'Galaxy Watch6 Classic 47mm',
    'Galaxy Buds 2',
    'Galaxy Buds2 Pro',
    'Galaxy Buds FE',
  ],
})
