<template>
  <div v-if="model">
    <template v-if="isBnn">
      <div class="cart-pre-order">
        <v-container>
          <v-row v-bind="{ align: 'center' }" class="mb-6">
            <v-col>
              <h1>Cart</h1>
            </v-col>
            <v-col class="text-back">
              <span @click="goBack()">
                &#60; กลับไปที่รายการก่อนหน้า
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="6" sm="12" class="gallery is-desktop">
              <template v-if="thumbnailList.length > 0">
                <PreviewImageSwiper :medias="thumbnailList" />
              </template>
            </v-col>
            <v-col md="6" sm="12" class="cart-order">
              <CartOrder />
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div>
        <CustomerForm />
      </div>
    </template>
    <template v-else>
      <Checkout />
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PreviewImageSwiper from '@/components/bnn/PreviewImageSwiper'
import CartOrder from '@/components/bnn/CartOrder'
import CustomerForm from '@/components/bnn/CustomerForm'
import branchTypes from '@/config/branchTypes'
// import routePage from '@/config/routePage'

export default {
  components: {
    CartOrder,
    CustomerForm,
    PreviewImageSwiper,

    Checkout: () => import('@/components/studio7/Checkout'),
  },

  computed: {
    ...mapState({
      isLoading: state => state.model.isLoading,
      isLoaded: state => state.model.isLoaded,
      campaign: state => state.campaign.current,
      campaignLoading: state => state.campaign.isLoading,
      model: state => state.model.selectModel,
      selectSKU: state => state.model.selectSKU,
      branchType: state => state.branchStore.branchType,
    }),

    ...mapGetters({
      additionalProduct: 'model/getAdditionalProduct',
    }),

    thumbnailList() {
      return (
        [{ ...this.selectSKU, imageUrl: this.selectSKU.image_url }] ??
        []
      )
    },

    isDeposit() {
      return this.branchType === this.branchTypes.BRANCH_TYPE_DEPOSIT
    },
  },

  data() {
    return {
      paymentId: null,
      isOutStock: false,
      loadingPayment: false,
      outSockMessage: '',
      branchTypes,
      //   form: {
      //     // id_card: localStorage.getItem('idCard') || '',
      //     isOutStock: false,
      //     product_id: null,
      //     branch_time_id: undefined,
      //     bundle_products: [],
      //     payment_type_id: null,
      //     is_delivery: true,
      //     delivery: undefined,

      //     first_name: localStorage.getItem('firstName') || '',
      //     last_name: localStorage.getItem('lastName') || '',
      //     phone: localStorage.getItem('phoneNumber') || '',
      //     email: localStorage.getItem('email') || '',
      //     address: localStorage.getItem('address') || '',
      //     sub_district: localStorage.getItem('sub_district') || '',
      //     district: localStorage.getItem('district') || '',
      //     province: localStorage.getItem('province') || '',
      //     postcode: localStorage.getItem('postcode') || '',
      //     checkReceipt: false,
      //     checkTaxPersonal: false,
      //     checkTaxJuristic: false,
      //     tax_id: '',
      //     taxFirstName: '',
      //     taxLastName: '',
      //     taxCompanyName: '',
      //     taxCompanyCode: '',
      //     taxAddress1: localStorage.getItem('taxAddress1') || '',
      //     taxAddress2: localStorage.getItem('taxAddress2') || '',
      //     taxAddress3: localStorage.getItem('taxAddress3') || '',
      //     taxProvince: localStorage.getItem('taxProvince') || '',
      //     taxDistrict: localStorage.getItem('taxDistrict') || '',
      //     taxSubDistrict: localStorage.getItem('taxSubDistrict') || '',
      //     taxPostcode: localStorage.getItem('taxPostcode') || '',
      //     taxPhoneNumber: '',
      //     billing_address: undefined,

      //     // other
      //     // for_work: localStorage.getItem('for_work'),

      //     /**
      //      * dev
      //      */
      //     // id_card: '1231231231231',
      //     // product_id: null,
      //     // branch_time_id: '',
      //     // bundle_products: [],
      //     // payment_type_id: null,
      //     // is_delivery: false,

      //     // first_name: '123123',
      //     // last_name: '12312',
      //     // phone: '12312',
      //     // email: 'aa@aaa.com',
      //     // address: '123123',
      //     // sub_district: 'คลองสาน',
      //     // district: 'คลองสาน',
      //     // province: 'กรุงเทพมหานคร',
      //     // postcode: '12323',

      //     // // other
      //     // for_work: false,
      //   },
    }
  },

  mounted() {
    if (!this.model) {
      this.$router
        .push({
          name: 'ProductDetail',
          params: {
            slug: this.$route.params?.slug,
          },
        })
        .catch(() => true)
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  },

  methods: {
    goBack() {
      this.$store.commit('cart/resetCouponCode')

      let backToPage =
        this.additionalProduct.length > 0
          ? 'CustomerAlsoBought'
          : 'ProductDetail'

      this.$router
        .push({
          name: backToPage,
          params: { slug: this.$route.params?.slug },
        })
        .catch(() => true)
      // if (this.isDeposit) {
      //   this.$router
      //     .push({
      //       name: 'ProductDetail',
      //       params: {
      //         slug: this.$route.params?.slug,
      //       },
      //     })
      //     .catch(() => true)
      // } else {
      //   this.$router
      //     .push({
      //       name: 'CustomerAlsoBought',
      //       params: {
      //         slug: this.$route.params?.slug,
      //       },
      //     })
      //     .catch(() => true)
      // }
    },
  },
}
</script>

<style lang="stylus" scoped>

.cart-pre-order
    margin-top: 50px
    padding: 2rem 4rem
    @media screen and (max-width: 960px)
      padding: 1rem
      margin-top: 70px

.is-desktop
  @media tablet
    display: none !important

.gallery
    display: flex
    justify-content: center
    padding 0 10rem 0 0
    margin-top: -2.2rem
    @media screen and (max-width: 960px)
      padding: 0
      display: flex
      justify-content: center
      width: 100%

.cart-order
  margin-top: -1rem

.text-back
  color $color-tertiary
  font-size: 1rem
  text-align: right
  cursor pointer
  display:  block
  font-weight: bold
  @media small
      display:  none
  @media medium
      display: none
</style>
