<template>
  <v-col cols="12">
    <template v-if="isShowDelivery(campaign.slug) && isFullPayment">
      <v-row
        v-if="isShowDelivery(campaign.slug)"
        v-bind="{ justify: 'center', 'no-gutters': true }"
        class="w-full"
      >
        <h2 class="py-8">คุณต้องการรับสินค้าด้วยแบบใด ?</h2>
      </v-row>

      <div class="action-layout">
        <div v-if="isShowDeliveryStore" class="store">
          <BaseButton
            v-if="disableReceiveByBranch"
            @click="onClick('store', null)"
            buttonName="store"
            :selected="isStore"
            :logo="
              require('@/assets/icon/icon-pick-at-store-type.svg')
            "
            :hasError="hasErrorReceiveOrder"
            label="บริการรับสินค้าหน้าร้าน"
          />
          <BaseButton
            v-else
            buttonName="store"
            disabled
            is-full
            :selected="isStore"
            :logo="
              require('@/assets/icon/icon-pick-at-store-type.svg')
            "
            :hasError="hasErrorReceiveOrder"
            :label="'บริการรับสินค้าหน้าร้าน'"
            desc="กรุณาเลือก บริการจัดส่งถึงบ้าน"
          />
        </div>
        <div v-if="isShowDeliveryHome" class="delivery">
          <BaseButton
            @click="onClick('delivery', null)"
            buttonName="delivery"
            :selected="isDelivery"
            :logo="require('@/assets/icon/icon-shipping-type.svg')"
            :hasError="hasErrorReceiveOrder"
            label="บริการจัดส่งถึงบ้าน"
          />
        </div>
      </div>
    </template>

    <!-- Delivery -->
    <template
      v-if="
        isDelivery &&
          isShowDeliveryHome &&
          isShowDelivery(campaign.slug)
      "
    >
      <v-row
        v-bind="{ justify: 'center', 'no-gutters': true }"
        class="w-full"
      >
        <h2 class="py-8">เลือกวิธีจัดส่งสินค้า</h2>
      </v-row>
      <v-row>
        <v-col
          v-for="(item, index) of deliveryItems"
          :key="item.sku"
          sm="12"
          md="6"
        >
          <BaseButton
            v-if="index === 0"
            @click="onClickDelivery(item)"
            :selected="item.sku === currentDeliverySku"
            :logo="item.image_url"
            :label="item.name"
            :desc="item.description"
            :disabled="!item.active"
          />
          <BaseButton
            v-if="index === 1"
            @click="onClickDelivery(item)"
            :selected="item.sku === currentDeliverySku"
            :logo="item.image_url"
            :label="item.name"
            :desc="item.description"
            :disabled="!item.active"
          />
          <BaseButton
            v-if="index > 1"
            @click="onClickDelivery(item)"
            :selected="item.sku === currentDeliverySku"
            :logo="item.image_url"
            :label="item.name"
            :desc="item.description"
            :disabled="!item.active"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span class="text-detail-payment-delivery payment-label">
            เริ่มจัดส่งตั้งแต่วันที่
            {{ shipDate }} *ตามเงื่อนไขที่บริษัทกำหนด
          </span>
        </v-col>
      </v-row>
    </template>

    <!-- In Store -->
    <template v-if="isStore && isShowDeliveryStore">
      <!-- <v-row
	   v-bind="{ justify: 'center', 'no-gutters': true }"
	   class="w-full" >
        <h2 class="py-8">เลือกวิธีชำระสินค้า</h2>
      </v-row>
      <div class="payment-type-layout">
        <div v-if="isShowBtnDeposit" class="deposit">
          <BaseButton
            @click="disabledBranchDeposit ? onClickDeposit() : false"
            buttonName="deposit"
            :selected="isDeposit"
            :logo="require('@/assets/deposit-payment.svg')"
            :hasError="hasErrorReceiveOrder"
            :disabled="!disabledBranchDeposit"
            :is-full="!disabledBranchDeposit"
            :label="'ชำระเงินมัดจำ'"
          />
        </div>
        <div v-if="isShowBtnFullPayment" class="full-payment">
          <BaseButton
            @click="disabledBranchFullPayment ? onClickFull() : false"
            buttonName="full-payment"
            :selected="isFullPayment"
            :logo="require('@/assets/full-payment.svg')"
            :hasError="hasErrorReceiveOrder"
            :disabled="!disabledBranchFullPayment"
            :is-full="!disabledBranchFullPayment"
            :label="'ชำระเงินเต็มจำนวน'"
          />
        </div>
      </div> -->

      <v-row
        v-bind="{ justify: 'center', 'no-gutters': true }"
        class="w-full py-8 text-center"
      >
        <v-col cols="12">
          <h2>สาขาที่ต้องการรับสินค้า</h2>
        </v-col>
        <v-col cols="12" class="text-warning">
          การจองและรับสินค้าที่สาขา บริษัทฯ
          ขอเช็คเปิดเครื่องเพื่อการรับประกันสินค้าทุกกรณี
          <br class="d-none d-md-block" />
          และขอสงวนสิทธิ์ยกเลิกคำสั่งซื้อหากลูกค้าไม่ประสงค์จะเปิดเช็คเครื่อง
        </v-col>
      </v-row>

      <div class="branch-time">
        <div class="branch">
          <v-autocomplete
            @click="onFocus(false)"
            @click:append="onFocus(false)"
            @click:prepend="onFocus(true)"
            v-model="branchProvince"
            data-pptr="branch_province"
            background-color="white"
            class="text-sm"
            :error="hasErrorBranchProvince"
            :items="provinceItems"
            placeholder="เลือกจังหวัด"
            ref="branch_time_id"
            :disabled="isDisabledSelectBranch"
            @change="resetBranchTime()"
          ></v-autocomplete>
        </div>
        <div class="branch">
          <v-autocomplete
            @click="onFocus(false)"
            @click:append="onFocus(false)"
            @click:prepend="onFocus(true)"
            v-model="branchId"
            data-pptr="branch_time_id"
            background-color="white"
            class="text-sm"
            :error="hasErrorStore"
            :items="branchData"
            :disabled="isDisabledSelectBranch"
            item-text="name"
            item-value="id"
            placeholder="เลือกสาขา"
            ref="branch_time_id"
            @change="resetBranchTime()"
          ></v-autocomplete>
        </div>
        <div class="time">
          <v-select
            @click="onFocus(false)"
            @click:append="onFocus(false)"
            @click:prepend="onFocus(true)"
            v-model="form.branch_time_id"
            data-pptr="branch_time_id"
            background-color="white"
            class="text-sm"
            :error="hasErrorStore"
            :items="timeRangeData"
            item-text="name"
            item-value="id"
            placeholder="ช่วงเวลา"
            ref="branch_time_id_detail"
          >
            <v-slot slot="selection" slot-scope="{ item }">
              <div class="v-select__selection">
                {{ item.name }}
              </div>
            </v-slot>
            <v-slot slot="item" slot-scope="{ item }">
              <div>
                {{ item.name }}
              </div>
            </v-slot>
          </v-select>
        </div>
      </div>
    </template>
  </v-col>
</template>

<script>
import colorBnn from '@/config/color'
import BaseButton from '@/components/bnn/BaseButton.vue'
import branchTypes from '@/config/branchTypes'
import { mapGetters, mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import {
  //   email,
  //   maxLength,
  //   numeric,
  requiredIf,
} from 'vuelidate/lib/validators'
import { isShowDelivery } from '@/utils/helpers'

export default {
  components: {
    BaseButton,
  },

  mixins: [validationMixin],

  computed: {
    ...mapState({
      // branchData: state => state.branchStore.data,
      selectSKU: state => state.model.selectSKU,
      delivery: state => state.cart.delivery,
      campaign: state => state?.campaign?.current?.data,
      branchType: state => state.branchStore.branchType,
      isLoading: state => state.branchStore.isLoading,
      selectDelivery: state => state?.bundle.delivery,
      paymentTypeData: state => state.paymentType.data,
    }),

    ...mapGetters({
      productItem: 'cart/productItem',
      deliveryItems: 'model/getBundleDelivery',
      disabledBranchDeposit: 'branchStore/disabledBranchDeposit',
      disabledBranchFullPayment:
        'branchStore/disabledBranchFullPayment',
      provinceItems: 'branchStore/getProvince',
    }),

    shipDate() {
      return this.selectSKU.shipping_date || this.campaign?.shipdate
    },

    branchData() {
      return this.$store.getters['branchStore/getBranchByProvince'](
        this.branchProvince,
      )
    },

    disableReceiveByBranch() {
      return (
        !this.disabledBranchDeposit || !this.disabledBranchFullPayment
      )
    },

    isDisabledSelectBranch() {
      // if has branch id param in url, disable select branch but if branch full enable select branch

      const queryParam = this.$route?.query

      if (!queryParam?.admin_quota?.toLowerCase() === 'true') {
        return false
      }

      if (queryParam?.branch_code) {
        const branchCode = queryParam?.branch_code
          ? queryParam?.branch_code.toLowerCase()
          : null
        return (
          this.branchData?.some(
            i => +i?.branch_code === +branchCode,
          ) && !this.isFullTimeRangeData
        )
      } else if (queryParam?.branch_id) {
        const branchId = queryParam?.branch_id
          ? queryParam?.branch_id.toLowerCase()
          : null
        return (
          this.branchData?.some(i => +i?.id === +branchId) &&
          !this.isFullTimeRangeData
        )
      }

      return false
    },

    isDelivery() {
      return this.delivery === 'delivery'
    },

    isStore() {
      return this.delivery === 'store'
    },

    isDeposit() {
      return this.branchType === branchTypes.BRANCH_TYPE_DEPOSIT
    },

    isFullPayment() {
      return this.branchType === branchTypes.BRANCH_TYPE_FULL_PAYMENT
    },

    disabledDeliveryStore() {
      return this.$store.getters[
        'branchStore/disabledDeliveryStore'
      ]()
    },

    timeRangeData() {
      return this.$store.getters['branchStore/getTimesByBranchId'](
        this.branchId,
      )
    },

    isFullTimeRangeData() {
      return (
        this.timeRangeData?.filter(i => i.active === true)?.length ===
        0
      )
    },

    timeRangeDataNotActive() {
      return this.$store.getters[
        'branchStore/getTimesByBranchIdNotActive'
      ](this.branchId)
    },

    receiveByBranch() {
      if (this.isDeposit) {
        return true
      } else {
        return this.checkReceiveBranch
      }
    },

    isShowDeliveryHome() {
      // EDIT: If select full payment, show delivery home only
      if (this.isDeposit) {
        return false
      }

      return this.campaign?.is_delivery_home ?? false
    },

    isShowDeliveryStore() {
      // EDIT: If select deposit payment, show delivery store only
      //   if (this.isFullPayment) {
      //     return false
      //   }

      return this.campaign?.is_delivery_store ?? false
    },

    isShowBtnDeposit() {
      if (this.campaign?.btn_deposit) {
        return (
          this.paymentTypeData?.filter(i => i.type === 'deposit')
            ?.length > 0
        )
      }
      return false
    },

    isShowBtnFullPayment() {
      if (
        this.campaign?.btn_full_customer ||
        this.campaign?.btn_full_staff
      ) {
        return (
          this.paymentTypeData?.filter(i => i.type !== 'deposit')
            ?.length > 0
        )
      }
      return false
    },

    currentDeliverySku() {
      return this?.selectDelivery
        ? Object.keys(this?.selectDelivery)?.[0] ?? null
        : null
    },
  },

  validations: {
    form: {
      branch_time_id: {
        required: requiredIf(function() {
          return this.receiveByBranch
        }),
      },
      branchProvince: {
        required: requiredIf(function() {
          return this.receiveByBranch
        }),
      },
    },
  },

  watch: {
    branchProvince(value) {
      this.form.branch_province = value
    },
    timeRangeData(items) {
      if ((items || []).length === 1) {
        this.form.branch_time_id = items[0].id
      }
    },

    disableReceiveByBranch() {
      this.autoSelectDelivery()
    },
  },

  props: {
    form: {
      type: Object,
      default: () => ({}),
    },

    hasErrorReceiveOrder: {
      type: Boolean,
      default: false,
    },

    hasErrorStore: {
      type: Boolean,
      default: false,
    },

    hasErrorBranchProvince: {
      type: Boolean,
      default: false,
    },
  },

  async mounted() {
    // await this.$store.dispatch('branchStore/getRefetchBranch')
    await this.getRefetchBranch()
    this.$nextTick(() => {
      this.autoSelectDelivery()
      if (this.$route?.query?.branch_code) {
        this.branchProvince = this.$store.getters[
          'branchStore/getProvinceByBranchCode'
        ](this.$route?.query?.branch_code)

        this.branchId = this.$store.getters[
          'branchStore/getBranchIdByBranchCode'
        ](this.$route?.query?.branch_code)
        return
      } else if (this.$route?.query?.branch_id) {
        this.branchProvince = this.$store.getters[
          'branchStore/getProvinceByBranchId'
        ](this.$route?.query?.branch_id)

        this.branchId = this.$route?.query?.branch_id
          ? +this.$route?.query?.branch_id.toLowerCase()
          : null
        return
      }
    })
  },

  data() {
    return {
      isShowDelivery,
      colorBnn,
      branchId: null,
      checkReceiveBranch: false,
      branchProvince: null,
      //   form: {
      //     branch_time_id: undefined,
      //   },
    }
  },

  methods: {
    async getRefetchBranch() {
      const queryParam = this.$route?.query
      const adminQuota = queryParam?.admin_quota
        ? queryParam?.admin_quota.toLowerCase() === 'true'
        : null

      const payload = {
        productId: this.productItem?.id,
        adminQuota: adminQuota ? 1 : 0,
      }
      await this.$store.dispatch(
        'branchStore/getRefetchBranch',
        payload,
      )
    },

    onFocus(value) {
      this.$store.dispatch('cart/showSummary', value)
      this.$forceUpdate()
    },

    onClick(value) {
      this.$store.commit('cart/delivery', value)
      if (value === 'delivery') {
        // this.$store.commit(
        //   'branchStore/setBranchType',
        //   branchTypes.BRANCH_TYPE_FULL_PAYMENT,
        // )
        this.$store.dispatch(
          'bundle/selectDelivery',
          this.deliveryItems?.[0],
        )
      } else {
        // this.$store.commit(
        //   'branchStore/setBranchType',
        //   branchTypes.BRANCH_TYPE_DEPOSIT,
        // )
        this.$store.dispatch('bundle/selectDelivery', null)
        // this.$store.dispatch('branchStore/getRefetchBranch')
        // this.getRefetchBranch()
      }
      this.$forceUpdate()

      // if (item) {
      //   this.$store.dispatch('bundle/selectDelivery', item)
      // }
      // if (item === this.BRANCH_TYPE_DEPOSIT) {
      //   this.$store.commit(
      //     'branchStore/setBranchType',
      //     branchTypes.BRANCH_TYPE_DEPOSIT,
      //   )
      //   this.$store.dispatch('branchStore/getRefetchBranch')
      //   this.$store.dispatch('bundle/selectDelivery', null)
      // }
    },

    onClickDelivery(item) {
      if (item) {
        this.$store.dispatch('bundle/selectDelivery', item)
        this.$forceUpdate()
      }
    },

    onClickFull() {
      this.$store.commit(
        'branchStore/setBranchType',
        branchTypes.BRANCH_TYPE_FULL_PAYMENT,
      )
      // this.$store.dispatch('branchStore/getRefetchBranch')
      this.getRefetchBranch()
      this.resetBranchTime()
    },

    onClickDeposit() {
      this.$store.commit(
        'branchStore/setBranchType',
        branchTypes.BRANCH_TYPE_DEPOSIT,
      )
      // this.$store.dispatch('branchStore/getRefetchBranch')
      this.getRefetchBranch()
      this.resetBranchTime()
    },

    strHaveBR(str) {
      // return str.match(/(<br>|<br\/>)/)
      return str
    },

    resetBranchTime() {
      this.form.branch_time_id = null
    },

    autoSelectDelivery() {
      if (this.isShowDeliveryStore) {
        if (!this.disableReceiveByBranch && !this.isLoading) {
          this.$store.commit('cart/delivery', 'delivery')
          // this.$store.commit(
          //   'branchStore/setBranchType',
          //   branchTypes.BRANCH_TYPE_FULL_PAYMENT,
          // )

          if (!this.currentDeliverySku) {
            this.$store.dispatch(
              'bundle/selectDelivery',
              this.deliveryItems?.[0],
            )
          }
        }
      } else {
        this.$store.commit('cart/delivery', 'delivery')
        if (!this.currentDeliverySku) {
          this.$store.dispatch(
            'bundle/selectDelivery',
            this.deliveryItems?.[0],
          )
        }
      }

      if (this.isDelivery) {
        !this.currentDeliverySku
          ? this.$store.dispatch(
              'bundle/selectDelivery',
              this.deliveryItems?.[0],
            )
          : null
      }
    },
  },
}
</script>

<style lang="stylus" scoped>

.action-layout
  display: flex
  width: 100%
  gap: 12px
  @media screen and (max-width: 960px)
    flex-wrap: wrap
  .store
    width: 50%
    padding: 12px 0
    @media screen and (max-width: 960px)
      width: 100%
  .delivery
    width: 50%
    padding: 12px 0
    @media screen and (max-width: 960px)
      width: 100%
.payment-type-layout
  display: flex
  width: 100%
  gap: 12px
  @media screen and (max-width: 960px)
    flex-direction: column
    width 100%
  .deposit
    width: 50%
    padding: 12px 0
    @media screen and (max-width: 960px)
      width: 100%

  .full-payment
    width: 50%
    padding: 12px 0
    @media screen and (max-width: 960px)
      width: 100%

::v-deep .v-select__selection
    max-width: 100%

.text-warning
  font-size: 0.875rem
  color: $color-red

  @media tablet
    font-size: 0.875rem

  @media small
    font-size: 11px
</style>
