<template>
  <div>
    <div>
      <div class="customer-form">
        <v-container>
          <ReceiveOrder
            :form.sync="form"
            :hasErrorReceiveOrder="
              !isStore && !isDelivery
                ? $v.form.delivery.$error
                : false
            "
            :hasErrorStore="
              isStore ? $v.form.branch_time_id.$error : false
            "
            :hasErrorBranchProvince="
              isStore ? $v.form.branch_province.$error : false
            "
          />
        </v-container>

        <section style="background: #fff">
          <SevenClubMember
            @verified="prepareDataForm"
            @error="autoFocusField('email')"
            @reset="memberLogout"
          />
        </section>

        <v-container>
          <v-row
            class="w-full"
            v-bind="{ justify: 'center', 'no-gutters': true }"
          >
            <h2 class="py-8">ที่อยู่ใบเสร็จและจัดส่ง</h2>
          </v-row>
          <div class="note-form-address text-red">
            <div>
              โปรดกรอกอีเมลและเบอร์โทรที่ติดต่อได้
            </div>
            <div>
              ขอสงวนสิทธิ์ไม่รับผิดชอบหากกรอกข้อมูลผิดทุกกรณี
            </div>
            <template v-if="campaign.data.order_limit > 0">
              <div>
                สงวนสิทธิ์ในการสั่งซื้อ 1 ท่าน /
                {{ campaign.data.order_limit }} เครื่อง / 1 เบอร์โทร /
                1 อีเมล เท่านั้น
              </div>
              <div>
                บริษัทจะขอยกเลิกออเดอร์หากตรวจพบว่าท่านได้ทำการสั่งจองเกินสิทธิ์แล้ว
              </div>
            </template>
          </div>

          <div class="d-flex justify-center">
            <form class="form-customer-layout">
              <v-col cols="12" class="text-dark-gray">
                <v-row>
                  <v-col cols="12" md="6" class="pb-0">
                    <label class="mb-18">
                      อีเมล <span class="text-red">*</span>
                    </label>
                    <v-text-field
                      @click="onFocus(false)"
                      @focus="onFocus(false)"
                      @blur="onFocus(true)"
                      :color="colorBnn['color-bnn']"
                      v-model="form.email"
                      background-color="white"
                      data-pptr="email"
                      class="input-customer text-sm mt-2"
                      :error="$v.form.email.$error"
                      type="email"
                      ref="email"
                      placeholder="อีเมล"
                    />
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                    <label class="mb-18">
                      เบอร์โทรศัพท์ <span class="text-red">*</span>
                    </label>
                    <v-text-field
                      @click="onFocus(false)"
                      @focus="onFocus(false)"
                      @blur="onFocus(true)"
                      :color="colorBnn['color-bnn']"
                      @keypress="isNumber($event)"
                      v-model="form.phone"
                      background-color="white"
                      data-pptr="phone"
                      class="input-customer text-sm mt-2"
                      :error="$v.form.phone.$error"
                      maxlength="10"
                      type="tel"
                      ref="phone"
                      placeholder="เบอร์โทรศัพท์"
                    />
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                    <label class="mb-18">
                      ชื่อ <span class="text-red">*</span>
                    </label>
                    <v-text-field
                      @click="onFocus(false)"
                      @focus="onFocus(false)"
                      @blur="onFocus(true)"
                      :color="colorBnn['color-bnn']"
                      v-model="form.first_name"
                      background-color="white"
                      data-pptr="first_name"
                      class="input-customer text-sm mt-2"
                      :error="$v.form.first_name.$error"
                      type="text"
                      ref="first_name"
                      placeholder="ชื่อ"
                    />
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                    <label class="mb-18">
                      นามสกุล <span class="text-red">*</span>
                    </label>
                    <v-text-field
                      @click="onFocus(false)"
                      @focus="onFocus(false)"
                      @blur="onFocus(true)"
                      :color="colorBnn['color-bnn']"
                      v-model="form.last_name"
                      background-color="white"
                      data-pptr="last_name"
                      class="input-customer text-sm mt-2"
                      :error="$v.form.last_name.$error"
                      type="text"
                      ref="last_name"
                      placeholder="นามสกุล"
                    />
                  </v-col>

                  <v-col cols="12" md="6" class="pb-0">
                    <label class="mb-18">
                      เลขบัตรประชนชน/Passport
                      <span class="text-red">*</span>
                    </label>
                    <!-- <v-text-field
                      v-model="form.customer_identity"
                      @click="onFocus(false)"
                      @focus="onFocus(false)"
                      @blur="onFocus(true)"
                      :color="colorBnn['color-bnn']"
                      :error="$v.form.customer_identity.$error"
                      :error-messages="
                        handleErrorCustomerIdentity(
                          'customer_identity',
                        )
                      "
                      :hide-details="
                        !$v.form.customer_identity.$error
                      "
                      :minlength="13"
                      :maxlength="17"
                      placeholder="x-xxxx-xxxxx-xx-x"
                      background-color="white"
                      data-pptr="customer_identity"
                      class="input-customer text-sm mt-2"
                      type="text"
                      ref="customer_identity"
                      oninput="this.value=this.value.slice(0,17).replace(/[^\d]+/g, '').replace(/(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/, '$1-$2-$3-$4-$5')"
                      inputmode="numeric"
                      @input="
                        autoFormatIdCard($event, 'customer_identity'),
                          $v.form.customer_identity.$reset()
                      "
                    /> -->
                    <v-row
                      v-bind="{
                        'no-gutters': true,
                      }"
                    >
                      <v-col cols="5" class="input-compact-container">
                        <v-select
                          v-model="customerIdentityType"
                          :items="[
                            {
                              text: 'เลขบัตรประชาชน',
                              value: 'id_card',
                            },
                            { text: 'Passport', value: 'passport' },
                          ]"
                          :color="colorBnn['color-bnn']"
                          :width="150"
                          :max-width="150"
                          class="input-compact"
                          outlined
                          hide-details
                        ></v-select>
                      </v-col>
                      <v-col cols="7" class="input-compact-container">
                        <v-text-field
                          v-if="customerIdentityType === 'id_card'"
                          outlined
                          :color="colorBnn['color-bnn']"
                          v-model="form.customer_identity"
                          background-color="white"
                          data-pptr="customer_identity"
                          class="input-customer text-sm input-compact"
                          :error="$v.form.customer_identity.$error"
                          :error-messages="
                            handleErrorCustomerIdentity(
                              'customer_identity',
                            )
                          "
                          :hide-details="
                            !$v.form.customer_identity.$error
                          "
                          ref="customer_identity"
                          maxlength="17"
                          @input="
                            autoFormatIdCard(
                              $event,
                              'customer_identity',
                            )
                          "
                          oninput="this.value=this.value.slice(0,17).replace(/[^\d]+/g, '').replace(/(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/, '$1-$2-$3-$4-$5')"
                          inputmode="numeric"
                          placeholder="เลขบัตรประชาชน"
                        />

                        <v-text-field
                          v-else
                          outlined
                          :color="colorBnn['color-bnn']"
                          v-model="form.customer_identity"
                          background-color="white"
                          data-pptr="customer_identity"
                          class="input-customer text-sm input-compact"
                          :error="$v.form.customer_identity.$error"
                          :error-messages="
                            handleErrorCustomerIdentity(
                              'customer_identity',
                            )
                          "
                          :hide-details="
                            !$v.form.customer_identity.$error
                          "
                          ref="customer_identity"
                          maxlength="12"
                          oninput="this.value=this.value.slice(0,12).replace(/[^\w]+/g, '')"
                          placeholder="Passport"
                        />
                      </v-col>
                    </v-row>
                  </v-col>

                  <div class="my-4"></div>
                  <v-col cols="12" md="12" class="pb-0">
                    <label class="mb-18">
                      บ้าน/อาคาร เลขที่
                      <span class="text-red">*</span>
                    </label>
                    <v-text-field
                      @click="onFocus(false)"
                      @focus="onFocus(false)"
                      @blur="onFocus(true)"
                      :color="colorBnn['color-bnn']"
                      v-model="form.address"
                      background-color="white"
                      data-pptr="address"
                      class="input-customer text-sm"
                      :error="$v.form.address.$error"
                      type="text"
                      ref="address"
                      placeholder="บ้านเลขที่,ห้อง,หมู่บ้าน,ชื่ออาคาร,ตึก,คอนโด"
                    />
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                    <label class="mb-18">
                      รหัสไปรษณีย์ <span class="text-red">*</span>
                    </label>
                    <v-text-field
                      @click="onFocus(false)"
                      @focus="onFocus(false)"
                      @blur="onBlurAutoComplete"
                      :color="colorBnn['color-bnn']"
                      @keypress="isNumber($event)"
                      v-model="form.postcode"
                      background-color="white"
                      data-pptr="postcode"
                      :error="$v.form.postcode.$error"
                      class="input-customer text-sm"
                      type="text"
                      ref="postcode"
                      maxlength="5"
                      placeholder="รหัสไปรษณีย์"
                      inputmode="numeric"
                    />
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                    <label class="mb-18">
                      จังหวัด <span class="text-red">*</span>
                    </label>
                    <v-autocomplete
                      @click="onFocusAutoComplete(false)"
                      @click:append="onFocusAutoComplete(false)"
                      @click:prepend="onFocusAutoComplete(true)"
                      :color="colorBnn['color-bnn']"
                      :error="$v.form.province.$error"
                      v-model="form.province"
                      background-color="white"
                      class="text-sm"
                      :class="{
                        'has-error': $v.form.province.$error,
                      }"
                      :items="provinceDataForDelivery"
                      data-pptr="province"
                      item-text="province"
                      item-value="province"
                      placeholder="จังหวัด"
                      ref="province"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                    <label class="mb-18">
                      เขต/อำเภอ <span class="text-red">*</span>
                    </label>
                    <v-autocomplete
                      @click="onFocusAutoComplete(false)"
                      @click:append="onFocusAutoComplete(false)"
                      @click:prepend="onFocusAutoComplete(true)"
                      :color="colorBnn['color-bnn']"
                      :error="$v.form.district.$error"
                      v-model="form.district"
                      background-color="white"
                      class="text-sm"
                      :class="{
                        'has-error': $v.form.district.$error,
                      }"
                      :items="districtData"
                      data-pptr="amphoe"
                      item-text="amphoe"
                      item-value="amphoe"
                      placeholder="เขต/อำเภอ"
                      ref="district"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0">
                    <label class="mb-18">
                      แขวง/ตำบล <span class="text-red">*</span>
                    </label>
                    <v-autocomplete
                      @click="onFocusAutoComplete(false)"
                      @click:append="onFocusAutoComplete(false)"
                      @click:prepend="onFocusAutoComplete(true)"
                      :color="colorBnn['color-bnn']"
                      :error="$v.form.sub_district.$error"
                      v-model="form.sub_district"
                      background-color="white"
                      class="text-sm"
                      :class="{
                        'has-error': $v.form.sub_district.$error,
                      }"
                      :items="subDistrictData"
                      data-pptr="sub_district"
                      item-text="district"
                      item-value="district"
                      placeholder="แขวง/ตำบล"
                      ref="sub_district"
                    ></v-autocomplete>
                  </v-col>

                  <template v-if="samsungHandraiser">
                    <v-col cols="12" md="6" class="pb-0">
                      <label class="mb-18">
                        มือถือที่ใช้อยู่ ณ ปัจจุบัน
                      </label>
                      <v-row>
                        <v-col cols="12" md="12" class="py-0">
                          <v-autocomplete
                            v-model="
                              form.additional_description.brand
                            "
                            :color="colorBnn['color-bnn']"
                            :items="additionalDescriptionBrand"
                            @click="onFocusAutoComplete(false)"
                            @click:append="onFocusAutoComplete(false)"
                            @click:prepend="onFocusAutoComplete(true)"
                            @click:clear="
                              form.additional_description.model = ''
                            "
                            clearable
                            background-color="white"
                            class="text-sm"
                            data-pptr="brand"
                            item-text="brand"
                            item-value="brand"
                            placeholder="ยี่ห้อมือถือที่ใช้งานอยู่"
                            ref="taxSubDistrict"
                          ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" md="12" class="py-0">
                          <v-autocomplete
                            v-model="
                              form.additional_description.model
                            "
                            :color="colorBnn['color-bnn']"
                            :items="additionalDescriptionModel"
                            :error="
                              $v.form.additional_description.model
                                .$error
                            "
                            :error-messages="
                              $v.form.additional_description.model
                                .$error
                                ? 'กรุณาเลือกรุ่นมือถือที่ใช้งานอยู่'
                                : ''
                            "
                            :class="{
                              'has-error':
                                $v.form.additional_description.model
                                  .$error,
                            }"
                            @click="onFocusAutoComplete(false)"
                            @click:append="onFocusAutoComplete(false)"
                            @click:prepend="onFocusAutoComplete(true)"
                            clearable
                            background-color="white"
                            class="text-sm"
                            data-pptr="model"
                            item-text="model"
                            item-value="model"
                            placeholder="รุ่นมือถือที่ใช้งานอยู่"
                            ref="taxSubDistrict"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" md="6" class="pb-0">
                      <label class="mb-18">
                        ลูกค้าสนใจ promotion แลกซื้อหรือไม่ /
                        หากสนใจเป็นสินค้าใด?
                      </label>
                      <v-autocomplete
                        v-model="
                          form.additional_description.interest_item
                        "
                        :color="colorBnn['color-bnn']"
                        :items="additionalDescriptionInterest"
                        @click="onFocusAutoComplete(false)"
                        @click:append="onFocusAutoComplete(false)"
                        @click:prepend="onFocusAutoComplete(true)"
                        clearable
                        background-color="white"
                        class="text-sm"
                        data-pptr="interest_item"
                        item-text="interest_item"
                        item-value="interest_item"
                        placeholder="หากสนใจ สนใจสินค้าใด"
                        ref="interest_item"
                      ></v-autocomplete>
                    </v-col>
                  </template>
                </v-row>

                <!-- <span class="font-semibold my-4">
                  คุณซื้อสินค้าเพื่อการทำงานในองค์กรหรือไม่ :
                </span>
                <v-row>
                  <v-col>
                    <v-radio-group
                      ref="for_work"
                      :error="$v.form.for_work.$error"
                      data-pptr="for_work"
                      row
                      v-model="form.for_work"
                    >
                      <v-radio
                        key="yes"
                        :label="`ใช่`"
                        value="yes"
                        data-pptr="for_work"
                        ref="for_work"
                      ></v-radio>
                      <v-radio
                        key="no"
                        :label="`ไม่ใช่`"
                        value="no"
                        data-pptr="for_work"
                        ref="for_work"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row> -->

                <!-- <v-row v-if="!isDeposit">
                  <v-col cols="12" md="6">
                    <h3 class="text-xl font-semibold">
                      <v-checkbox
                        :input-value="checkReceiveBranch"
                        :true-value="false"
                        color="#ffd400"
                        label="ต้องการรรับสินค้าที่หน้าร้านหรือไม่"
                        background-color="#f5f5f7"
                        @click="
                          checkReceiveBranch = !checkReceiveBranch
                        "
                      >
                      </v-checkbox>
                    </h3>
                  </v-col>
                </v-row> -->

                <!-- <div class="w-full" v-if="receiveByBranch">
                  <h3 class="text-xl font-semibold my-4">
                    สาขาที่ต้องการรับสินค้า :
                  </h3>
                  <div class="branch-time">
                    <div class="branch">
                      <v-autocomplete
                        v-model="branchId"
                        data-pptr="branch_time_id"
                        background-color="white"
                        class="text-sm"
                        :error="$v.form.branch_time_id.$error"
                        :items="branchData"
                        item-text="name"
                        item-value="id"
                        placeholder="เลือกสาขา"
                        ref="branch_time_id"
                        @change="resetBranchTime()"
                      ></v-autocomplete>
                    </div>
                    <div class="time">
                      <v-autocomplete
                        v-model="form.branch_time_id"
                        data-pptr="branch_time_id"
                        background-color="white"
                        class="text-sm"
                        :error="$v.form.branch_time_id.$error"
                        :items="timeRangeData"
                        item-text="name"
                        item-value="id"
                        placeholder="ช่วงเวลา"
                        ref="branch_time_id"
                      >
                        <v-slot
                          slot="selection"
                          slot-scope="{ item }"
                        >
                          <div
                            class="v-select__selection"
                            :class="{
                              select_multiple_line: strHaveBR(
                                item.name,
                              ),
                            }"
                            v-html="item.name"
                          ></div>
                        </v-slot>
                        <v-slot slot="item" slot-scope="{ item }">
                          <div
                            :class="{
                              select_multiple_line: strHaveBR(
                                item.name,
                              ),
                            }"
                            v-html="item.name"
                          ></div>
                        </v-slot>
                      </v-autocomplete>
                    </div>
                  </div>
                </div> -->

                <v-row>
                  <v-col v-if="customFieldOnCart" cols="12" md="12">
                    <h3 class="text-xl font-semibold">
                      <v-checkbox
                        :input-value="form.need_true_phone"
                        :true-value="true"
                        :color="colorBnn['color-bnn']"
                        label="ต้องการเปิดเบอร์ True รับส่วนลดสูงสุด 8,800 บาท"
                        background-color="#f5f5f7"
                        @click="checkNeedTruePhone"
                      ></v-checkbox>
                    </h3>
                  </v-col>
                  <v-col cols="12">
                    <h3 class="text-xl font-semibold">
                      <v-checkbox
                        v-model="form.checkReceipt"
                        :true-value="true"
                        :color="colorBnn['color-bnn']"
                        label="ต้องการรับใบกำกับภาษี / แก้ไขใบเสร็จ"
                        background-color="#f5f5f7"
                      ></v-checkbox>
                    </h3>
                    <div class="warning-tax-invoice">
                      <div class="warning-tax-invoice-title">
                        <span class="text-alert">สำคัญ!</span>
                        ใบกำกับภาษีของท่านจะถูกจัดส่งทางอีเมล
                      </div>
                      <div>
                        กรุณาตรวจสอบชื่อและที่อยู่ในการออกใบกำกับภาษีให้ถูกต้องทุกครั้ง
                        ทางบริษัทฯขอสงวนสิทธิ์ในการแก้ไขใบกำกับภาษี
                      </div>
                    </div>
                  </v-col>

                  <template v-if="form.checkReceipt">
                    <v-col cols="12" md="3">
                      <v-radio-group
                        v-model="form.taxInvoiceType"
                        inline
                        :error="$v.form.taxInvoiceType.$error"
                        ref="taxInvoiceType"
                        data-pptr="taxInvoiceType"
                      >
                        <v-radio
                          :label="`ใบกำกับภาษีส่วนบุคคล`"
                          value="PERSONAL"
                          :color="colorBnn['color-bnn']"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-radio-group
                        v-model="form.taxInvoiceType"
                        inline
                        :error="$v.form.taxInvoiceType.$error"
                        ref="taxInvoiceType"
                        data-pptr="taxInvoiceType"
                      >
                        <v-radio
                          :label="`ใบกำกับภาษีนิติบุคคล`"
                          value="CORPORATION"
                          :color="colorBnn['color-bnn']"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>

                    <!-- <v-col cols="12" class="pb-0">
                    <v-row v-show="form.checkReceipt" no-gutters>
                      <v-col
                        cols="12"
                        md="3"
                        class="pt-0 align-self-center"
                      >
                        <v-checkbox
                          class="mt-0"
                          :input-value="form.checkTaxPersonal"
                          :label="`ใบกำกับภาษีส่วนบุคคล`"
                          :true-value="true"
                          :color="colorBnn['color-bnn']"
                          background-color="#f5f5f7"
                          @click="checkTax(1)"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                        class="pt-0 align-self-center"
                      >
                        <v-checkbox
                          class="mt-0"
                          :input-value="form.checkTaxJuristic"
                          :label="`ใบกำกับภาษีนิติบุคคล`"
                          :true-value="true"
                          :color="colorBnn['color-bnn']"
                          background-color="#f5f5f7"
                          @click="checkTax(2)"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col> -->

                    <v-col cols="12" class="pb-0">
                      <v-row>
                        <v-col cols="12" md="12">
                          <label class="mb-18">
                            เลขที่ประจำตัวผู้เสียภาษี
                            <span class="text-red">*</span>
                          </label>
                          <v-text-field
                            @click="onFocus(false)"
                            @focus="onFocus(false)"
                            @blur="onFocus(true)"
                            :color="colorBnn['color-bnn']"
                            v-model="form.tax_id"
                            background-color="white"
                            data-pptr="tax_id"
                            class="input-customer text-sm"
                            :error="
                              $v.form.tax_id.$error ||
                                errorTaxIdFormat
                            "
                            :error-messages="
                              errorTaxIdFormat
                                ? 'กรุณากรอกเลขที่ประจำตัวผู้เสียภาษีให้ถูกต้อง'
                                : ''
                            "
                            ref="tax_id"
                            maxlength="17"
                            @input="
                              autoFormatIdCard($event, 'tax_id'),
                                $v.form.tax_id.$reset()
                            "
                            oninput="this.value=this.value.slice(0,17).replace(/[^\d]+/g, '').replace(/(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/, '$1-$2-$3-$4-$5')"
                            placeholder="เลขที่ประจำตัวผู้เสียภาษี"
                          />
                        </v-col>

                        <!-- Personal -->
                        <template v-if="checkTaxPersonal">
                          <v-col cols="12" md="12">
                            <v-row
                              v-bind="{
                                'no-gutters': true,
                              }"
                            >
                              <v-col
                                v-if="isFromStockCard"
                                cols="5"
                                sm="4"
                                class="input-compact-container"
                              >
                                <v-select
                                  v-model="checkTaxPersonalType"
                                  :items="[
                                    {
                                      text: 'เลขประจำตัวผู้เสียภาษี',
                                      value: 'id_card',
                                    },
                                    {
                                      text: 'Passport',
                                      value: 'passport',
                                    },
                                  ]"
                                  :color="colorBnn['color-bnn']"
                                  :width="150"
                                  :max-width="150"
                                  class="input-compact"
                                  outlined
                                  hide-details
                                ></v-select>
                              </v-col>
                              <v-col
                                :cols="isFromStockCard ? 7 : 12"
                                :sm="isFromStockCard ? 8 : 12"
                                :class="[
                                  {
                                    'input-compact-container': isFromStockCard,
                                  },
                                ]"
                              >
                                <v-text-field
                                  v-if="
                                    checkTaxPersonalType === 'id_card'
                                  "
                                  @click="onFocus(false)"
                                  @focus="onFocus(false)"
                                  @blur="onFocus(true)"
                                  :color="colorBnn['color-bnn']"
                                  v-model="form.tax_id"
                                  background-color="white"
                                  data-pptr="tax_id"
                                  class="input-customer text-sm input-compact"
                                  :error="$v.form.tax_id.$error"
                                  :error-messages="
                                    handleErrorCustomerIdentity(
                                      'tax_id',
                                    )
                                  "
                                  :hide-details="
                                    !$v.form.tax_id.$error
                                  "
                                  ref="tax_id"
                                  maxlength="17"
                                  @input="
                                    autoFormatIdCard(
                                      $event,
                                      'tax_id',
                                    ),
                                      $v.form.tax_id.$reset()
                                  "
                                  oninput="this.value=this.value.slice(0,17).replace(/[^\d]+/g, '').replace(/(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/, '$1-$2-$3-$4-$5')"
                                  placeholder="เลขประจำตัวผู้เสียภาษี"
                                />

                                <v-text-field
                                  v-if="
                                    checkTaxPersonalType ===
                                      'passport'
                                  "
                                  @click="onFocus(false)"
                                  @focus="onFocus(false)"
                                  @blur="onFocus(true)"
                                  :color="colorBnn['color-bnn']"
                                  v-model="form.tax_id"
                                  background-color="white"
                                  data-pptr="tax_id"
                                  class="input-customer text-sm input-compact"
                                  :error="$v.form.tax_id.$error"
                                  :error-messages="
                                    handleErrorCustomerIdentity(
                                      'tax_id',
                                    )
                                  "
                                  :hide-details="
                                    !$v.form.tax_id.$error
                                  "
                                  ref="tax_id"
                                  maxlength="12"
                                  oninput="this.value=this.value.slice(0,12).replace(/[^\w]+/g, '')"
                                  placeholder="Passport"
                                />
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" md="6" class="pb-0">
                            <label class="mb-18">
                              ชื่อ
                              <span class="text-red">*</span>
                            </label>
                            <v-text-field
                              @click="onFocus(false)"
                              @focus="onFocus(false)"
                              @blur="onFocus(true)"
                              :color="colorBnn['color-bnn']"
                              v-model="form.taxFirstName"
                              background-color="white"
                              data-pptr="taxFirstName"
                              class="input-customer text-sm"
                              :error="$v.form.taxFirstName.$error"
                              ref="taxFirstName"
                              maxlength="100"
                              type="text"
                              placeholder="ชื่อ"
                            />
                          </v-col>
                          <v-col cols="12" md="6" class="pb-0">
                            <label class="mb-18">
                              นามสกุล
                              <span class="text-red">*</span>
                            </label>
                            <v-text-field
                              @click="onFocus(false)"
                              @focus="onFocus(false)"
                              @blur="onFocus(true)"
                              :color="colorBnn['color-bnn']"
                              v-model="form.taxLastName"
                              background-color="white"
                              data-pptr="taxLastName"
                              class="input-customer text-sm"
                              :error="$v.form.taxLastName.$error"
                              maxlength="100"
                              ref="taxLastName"
                              type="text"
                              placeholder="นามสกุล"
                            />
                          </v-col>
                        </template>
                        <!-- Company -->
                        <template v-if="checkTaxJuristic">
                          <v-col cols="12" md="12">
                            <v-text-field
                              @click="onFocus(false)"
                              @focus="onFocus(false)"
                              @blur="onFocus(true)"
                              :color="colorBnn['color-bnn']"
                              v-model="form.tax_id"
                              background-color="white"
                              data-pptr="tax_id"
                              class="input-customer text-sm input-compact"
                              :error="$v.form.tax_id.$error"
                              :error-messages="
                                handleErrorCustomerIdentity('tax_id')
                              "
                              :hide-details="!$v.form.tax_id.$error"
                              ref="tax_id"
                              maxlength="17"
                              @input="
                                autoFormatIdCard($event, 'tax_id'),
                                  $v.form.tax_id.$reset()
                              "
                              oninput="this.value=this.value.slice(0,17).replace(/[^\d]+/g, '').replace(/(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/, '$1-$2-$3-$4-$5')"
                              placeholder="เลขประจำตัวผู้เสียภาษี"
                            />
                          </v-col>
                          <v-col cols="12" md="6" class="pb-0">
                            <label class="mb-18">
                              ชื่อบริษัท
                              <span class="text-red">*</span>
                            </label>
                            <v-text-field
                              @click="onFocus(false)"
                              @focus="onFocus(false)"
                              @blur="onFocus(true)"
                              :color="colorBnn['color-bnn']"
                              v-model="form.taxCompanyName"
                              background-color="white"
                              data-pptr="taxCompanyName"
                              class="input-customer text-sm"
                              :error="$v.form.taxCompanyName.$error"
                              maxlength="100"
                              type="text"
                              ref="taxCompanyName"
                              placeholder="ชื่อบริษัท"
                            />
                          </v-col>
                          <v-col cols="12" md="6" class="pb-0">
                            <label class="mb-18">
                              รหัสสาขา
                              <span class="text-red">*</span>
                            </label>
                            <v-text-field
                              @click="onFocus(false)"
                              @focus="onFocus(false)"
                              @blur="onFocus(true)"
                              :color="colorBnn['color-bnn']"
                              v-model="form.taxCompanyCode"
                              background-color="white"
                              data-pptr="taxCompanyCode"
                              class="input-customer text-sm"
                              :error="$v.form.taxCompanyCode.$error"
                              maxlength="100"
                              type="text"
                              ref="taxCompanyCode"
                              placeholder="รหัสสาขา"
                            />
                          </v-col>
                        </template>

                        <v-col cols="12">
                          <h3 class="text-xl">
                            <v-checkbox
                              class="mt-0"
                              v-model="sameAsDeliveryAddress"
                              :label="`ที่อยู่เดียวกับที่อยู่จัดส่ง`"
                              :true-value="true"
                              :color="colorBnn['color-bnn']"
                              background-color="#f5f5f7"
                              @change="checkSameAsDeliveryAddress"
                            ></v-checkbox>
                          </h3>
                        </v-col>

                        <template v-if="!sameAsDeliveryAddress">
                          <v-col cols="12" md="6" class="pb-0">
                            <label class="mb-18">
                              บ้าน/อาคาร/เลขที่
                              <span class="text-red">*</span>
                            </label>
                            <v-text-field
                              @click="onFocus(false)"
                              @focus="onFocus(false)"
                              @blur="onFocus(true)"
                              :color="colorBnn['color-bnn']"
                              v-model="form.taxAddress1"
                              background-color="white"
                              data-pptr="taxAddress1"
                              class="input-customer text-sm"
                              :error="$v.form.taxAddress1.$error"
                              maxlength="100"
                              type="text"
                              ref="taxAddress1"
                              placeholder="บ้าน/อาคาร/เลขที่"
                            />
                          </v-col>
                          <v-col cols="12" md="6" class="pb-0">
                            <label class="mb-18">
                              หมู่บ้าน/อาคาร/สถานที่
                            </label>
                            <v-text-field
                              @click="onFocus(false)"
                              @focus="onFocus(false)"
                              @blur="onFocus(true)"
                              :color="colorBnn['color-bnn']"
                              v-model="form.taxAddress2"
                              background-color="white"
                              data-pptr="taxAddress2"
                              class="input-customer text-sm"
                              maxlength="100"
                              type="text"
                              ref="taxAddress2"
                              placeholder="หมู่บ้าน/อาคาร/สถานที่"
                            />
                          </v-col>
                          <v-col cols="12" md="12" class="pb-0">
                            <label class="mb-18">
                              ที่อยู่/ถนน/ซอย
                              <span class="text-red">*</span>
                            </label>
                            <v-text-field
                              @click="onFocus(false)"
                              @focus="onFocus(false)"
                              @blur="onFocus(true)"
                              :color="colorBnn['color-bnn']"
                              v-model="form.taxAddress3"
                              background-color="white"
                              data-pptr="taxAddress3"
                              class="input-customer text-sm"
                              :error="$v.form.taxAddress3.$error"
                              maxlength="100"
                              type="text"
                              ref="taxAddress3"
                              placeholder="ที่อยู่/ถนน/ซอย"
                            />
                          </v-col>
                          <v-col cols="12" md="6" class="pb-0">
                            <label class="mb-18">
                              จังหวัด
                              <span class="text-red">*</span>
                            </label>
                            <v-autocomplete
                              @click="onFocusAutoComplete(false)"
                              @click:append="
                                onFocusAutoComplete(false)
                              "
                              @click:prepend="
                                onFocusAutoComplete(true)
                              "
                              :color="colorBnn['color-bnn']"
                              v-model="form.taxProvince"
                              background-color="white"
                              class="text-sm input-transparent"
                              :class="{
                                'has-error':
                                  $v.form.taxProvince.$error,
                              }"
                              :items="provinceData"
                              data-pptr="taxProvince"
                              item-text="province"
                              item-value="province"
                              :error="$v.form.taxProvince.$error"
                              placeholder="จังหวัด"
                              ref="taxProvince"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="6" class="pb-0">
                            <label class="mb-18">
                              เขต/อำเภอ
                              <span class="text-red">*</span>
                            </label>
                            <v-autocomplete
                              @click="onFocusAutoComplete(false)"
                              @click:append="
                                onFocusAutoComplete(false)
                              "
                              @click:prepend="
                                onFocusAutoComplete(true)
                              "
                              :color="colorBnn['color-bnn']"
                              v-model="form.taxDistrict"
                              background-color="white"
                              class="text-sm"
                              :class="{
                                'has-error':
                                  $v.form.taxDistrict.$error,
                              }"
                              :error="$v.form.taxDistrict.$error"
                              :items="taxDistrictData"
                              data-pptr="taxDistrict"
                              item-text="amphoe"
                              item-value="amphoe"
                              placeholder="เขต/อำเภอ"
                              ref="taxDistrict"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="6" class="pb-0">
                            <label class="mb-18">
                              แขวง/ตำบล
                              <span class="text-red">*</span>
                            </label>
                            <v-autocomplete
                              @click="onFocusAutoComplete(false)"
                              @click:append="
                                onFocusAutoComplete(false)
                              "
                              @click:prepend="
                                onFocusAutoComplete(true)
                              "
                              :color="colorBnn['color-bnn']"
                              v-model="form.taxSubDistrict"
                              background-color="white"
                              class="text-sm"
                              :class="{
                                'has-error':
                                  $v.form.taxSubDistrict.$error,
                              }"
                              :error="$v.form.taxSubDistrict.$error"
                              :items="taxSubDistrictData"
                              data-pptr="taxSubDistrict"
                              item-text="district"
                              item-value="district"
                              @change="
                                persist('taxSubDistrict', $event)
                              "
                              placeholder="แขวง/ตำบล"
                              ref="taxSubDistrict"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="6" class="pb-0">
                            <label class="mb-18">
                              รหัสไปรษณีย์
                              <span class="text-red">*</span>
                            </label>
                            <v-text-field
                              @click="onFocus(false)"
                              @focus="onFocus(false)"
                              @blur="onFocus(true)"
                              :color="colorBnn['color-bnn']"
                              @keypress="isNumber($event)"
                              v-model="form.taxPostcode"
                              background-color="white"
                              data-pptr="taxPostcode"
                              :error="$v.form.taxPostcode.$error"
                              class="input-customer text-sm"
                              type="text"
                              ref="taxPostcode"
                              maxlength="5"
                              placeholder="รหัสไปรษณีย์"
                              inputmode="numeric"
                            />
                          </v-col>
                          <v-col cols="12" md="6" class="pb-0">
                            <label class="mb-18">
                              เบอร์โทรศัพท์
                              <span class="text-red">*</span>
                            </label>
                            <v-text-field
                              @click="onFocus(false)"
                              @focus="onFocus(false)"
                              @blur="onFocus(true)"
                              :color="colorBnn['color-bnn']"
                              @keypress="isNumber($event)"
                              v-model="form.taxPhoneNumber"
                              background-color="white"
                              data-pptr="taxPhoneNumber"
                              class="input-customer text-sm"
                              :error="$v.form.taxPhoneNumber.$error"
                              maxlength="10"
                              type="tel"
                              ref="taxPhoneNumber"
                              placeholder="เบอร์โทรศัพท์"
                            />
                          </v-col>
                        </template>
                      </v-row>
                    </v-col>
                  </template>
                </v-row>
              </v-col>
            </form>
          </div>
        </v-container>
      </div>

      <!-- <div v-if="!isDeposit" class="d-flex justify-center">
        <v-container>
          <div class="form-customer-layout">
            <Delivery
              :hasError="$v.form.delivery.$error"
              :postCode="form.postcode"
              @onSelectDelivery="onSelectDelivery"
            />
          </div>
        </v-container>
      </div> -->
    </div>
    <div>
      <div>
        <v-container>
          <Payment
            :loading="loadingPayment"
            :isErrorRequirePayment="$v.form.payment_type_id.$error"
            @onSubmit="onSubmit()"
            @onSelectPaymentMethod="selectPayment"
          />
        </v-container>
      </div>
    </div>
    <div class="is-desktop">
      <SummaryCart :isShowDetailPrice="true" :isShowAction="false" />
    </div>
    <!-- Qrcode SCB-->
    <v-dialog
      v-model="scbQrcodeDialog"
      falsewidth="500px"
      max-width="400px"
    >
      <v-card style="overflow:hidden">
        <div class="py-4">
          <v-row v-bind="{ justify: 'center' }">
            <img
              width="50px"
              height="50px"
              :src="require('@/assets/scb-easy.svg')"
            />
          </v-row>
        </div>
        <v-card-text class="scb-easy-modal">
          <div>
            <v-row v-bind="{ justify: 'center' }">
              <div class="text-white py-4 px-5 text-center">
                <h2>
                  <p>
                    กรุณาแสกน QR Code
                  </p>
                  <p>เพื่อชำระผ่าน SCB Easy</p>
                </h2>
              </div>
              <div class="scb-card-qrcode">
                <qrcode-vue :value="deeplink" size="200"></qrcode-vue>
              </div>
              <div class="pt-6" v-if="isMobile()">
                <v-btn
                  @click="openSCB()"
                  color="white"
                  outlined
                  class="button-back-scb"
                >
                  <span class="text-white">Open SCB EASY</span>
                </v-btn>
              </div>
            </v-row>
          </div>
          <v-card-actions>
            <v-row v-bind="{ justify: 'center' }">
              <div class="pt-6">
                <v-btn
                  color="#FFF"
                  text
                  @click="scbQrcodeDialog = false"
                  outlined
                  class="button-back-scb"
                >
                  <span class="text-white">
                    ย้อนกลับ
                  </span>
                </v-btn>
              </div>
            </v-row>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Qrcode SCB-->

    <!-- TODO: Remove this after the handraiser campaign is finished -->
    <v-dialog
      v-model="showWarningDialog"
      persistent
      max-width="340"
      max-height="340"
    >
      <v-card>
        <v-card-title>
          <div class="text-center w-full font-bold">
            อย่าลืมใช้โค้ด Handraiser
            <br />
            เพื่อรับสิทธิพิเศษ!
          </div>
        </v-card-title>
        <v-card-actions>
          <v-row class="mx-0">
            <v-col cols="6" sm="6">
              <Button
                @click="showWarningDialog = false"
                block
                depressed
                color="color-bnn-gray-light-2"
                title="กลับไปใช้โค้ด"
              />
            </v-col>
            <v-col cols="6" sm="6">
              <Button
                @click="
                  confirmDialog = true
                  onSubmit()
                "
                block
                depressed
                color="color-bnn"
                title="ดำเนินการต่อ"
              />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import colorBnn from '@/config/color'
// import Delivery from '@/components/bnn/Delivery'
import Payment from '@/components/bnn/Payment'
import ReceiveOrder from '@/components/bnn/ReceiveOrder'
import SummaryCart from '@/components/bnn/SummaryCart'
import Button from '@/components/bnn/Button'
import { showKBankEmbedUI } from '@/utils/kbank'
import axios from 'axios'
import QrcodeVue from 'qrcode.vue'
import { mapGetters, mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import {
  P_KBANK_DEPOSIT,
  P_KRUNGSRI_DEPOSIT,
  P_KBANK_INSTALLMENT,
  P_KBANK_FULL,
  P_2C2P_DEPOSIT,
  P_KRUNGSRI_FULL,
  P_2C2P_FULL,
  P_KRUNGSRI_INSTALLMENT,
  P_2C2P_INSTALLMENT,
  P_2C2P_UOB_INSTALLMENT,
  P_KRUNGSRI_FIRST_CHOICE_INSTALLMENT,
  P_2C2P_CITYBANK_INSTALLMENT,
  P_SCBEASY_FULL,
  P_SCBEASY_CREDIT_FULL,
  P_SCBEASY_DEPOSIT,
  P_SCBEASY_CREDIT_DEPOSIT,
  P_SCBEASY_INSTALLMENT,
  P_KBANK_QR_DEPOSIT,
  P_KBANK_QR_FULL,
  P_KTC_REDIRECT,
  P_KTC_INSTALLMENT,
  P_2C2P_QR_REDIRECT,
  P_PAY_AT_STORE,
} from '@/config/payment.json'
import {
  required,
  email,
  maxLength,
  numeric,
  requiredIf,
} from 'vuelidate/lib/validators'
import branchTypes from '@/config/branchTypes'
import additionalDescription from '@/config/additionalDescription'
import { STOCK_CARD_TYPE } from '@/config/stockCard'
import {
  validateIdCard,
  validatePassport,
  validateStartWithZero,
} from '@/utils/helpers'

export default {
  name: 'CustomerForm',

  mixins: [validationMixin],

  components: {
    // Delivery,
    Payment,
    ReceiveOrder,
    QrcodeVue,
    SummaryCart,
    Button,
    SevenClubMember: () =>
      import('@/components/bnn/SevenClubMember.vue'),
  },

  computed: {
    ...mapState({
      isLoggedIn: state => state.auth.isLoggedIn,
      branchData: state => state.branchStore.data,
      selectDelivery: state => state.cart.delivery,
      recaptchaKey: state => state.recaptcha.key,
      branchType: state => state.branchStore.branchType,
      coupon: state => state.cart.coupon,
      cartDelivery: state => state.cart.delivery,
      campaign: state => state.campaign.current,
      selectBundleDelivery: state => state?.bundle?.delivery,
      paymentId: state => state.paymentType?.paymentId,
      installmentPlanSelected: state =>
        state.paymentType?.installmentPlan,
      //   selectSKU: state => state.model.selectSKU,
    }),
    ...mapGetters({
      // haveDeposit: 'cart/haveDeposit',
      // priceDeposit: 'cart/priceDeposit',
      bundles: 'model/getBundles',
      additionalProduct: 'model/getAdditionalProduct',
      appleCare: 'model/getAppleCare',
      productItem: 'cart/productItem',
      bundleItems: 'cart/bundleItems',
      deliveryItems: 'cart/deliveryItems',
      isSoldOut: 'model/getSoldOut',
      accessoryBundles: 'model/getAccessoryBundles',
    }),

    customFieldOnCart() {
      return this.campaign.data?.custom_field_on_cart
    },

    samsungHandraiser() {
      return this.campaign.data?.handraiser === 'samsung'
    },

    isDelivery() {
      return (
        this.cartDelivery === 'delivery' || this.cartDelivery === true
      )
    },

    isStore() {
      return this.cartDelivery === 'store'
    },

    provinceDataForDelivery() {
      const currentDeliverySku =
        Object.keys(this?.selectBundleDelivery)?.[0] ?? null
      if (
        currentDeliverySku &&
        currentDeliverySku?.toUpperCase() === 'SERV_TRANS300'
      ) {
        return this.$store.getters[
          'thailandStore/getProvince'
        ]?.filter(i => i?.province?.indexOf(`กรุงเทพมหานคร`) > -1)
      }
      return this.$store.getters['thailandStore/getProvince']
    },

    provinceData() {
      return this.$store.getters['thailandStore/getProvince']
    },

    districtData() {
      return this.$store.getters['thailandStore/getDistrict'](
        this.form.province,
      )
    },
    taxDistrictData() {
      return this.$store.getters['thailandStore/getDistrict'](
        this.form.taxProvince,
      )
    },
    zipcodeData() {
      return this.$store.getters['thailandStore/getZipcode'](
        this.form.district,
        this.form.sub_district,
      )
    },
    taxZipcodeData() {
      return this.$store.getters['thailandStore/getZipcode'](
        this.form.taxDistrict,
        this.form.taxSubDistrict,
      )
    },
    taxSubDistrictData() {
      return this.$store.getters['thailandStore/getSubDistrict'](
        this.form.taxProvince,
        this.form.taxDistrict,
      )
    },

    subDistrictData() {
      return this.$store.getters['thailandStore/getSubDistrict'](
        this.form.province,
        this.form.district,
      )
    },

    timeRangeData() {
      return this.$store.getters['branchStore/getTimesByBranchId'](
        this.branchId,
      )
    },

    timeRangeDataNotActive() {
      return this.$store.getters[
        'branchStore/getTimesByBranchIdNotActive'
      ](this.branchId)
    },

    isDeposit() {
      return this.branchType === this.branchTypes.BRANCH_TYPE_DEPOSIT
    },

    receiveByBranch() {
      if (this.isDeposit) {
        return true
      } else {
        return this.checkReceiveBranch
      }
    },

    isVerifyRecaptcha() {
      return this.campaign?.data?.is_verify_recaptcha === 1
    },

    checkTaxPersonal() {
      return this.form.taxInvoiceType === 'PERSONAL'
    },

    checkTaxJuristic() {
      return this.form.taxInvoiceType === 'CORPORATION'
    },

    additionalDescriptionBrand() {
      let brands = additionalDescription.model.map(item => item.brand)
      return brands.filter(
        (brand, index) => brands.indexOf(brand) === index,
      )
    },

    additionalDescriptionModel() {
      return additionalDescription.model
        .filter(
          item =>
            item.brand === this.form.additional_description.brand,
        )
        .map(item => item.model)
    },

    additionalDescriptionInterest() {
      return additionalDescription.interestItem
    },

    decryptedData() {
      return this.$store.getters['user/getData']
    },

    isFromStockCard() {
      return !!this.$route.query?.t
    },
  },

  data() {
    return {
      paymentOptions: {
        payment8: {
          term: null, // bay
          cardType: 'creditcard', // bay
        },
        payment13: {
          term: null, // bay
          cardType: 'loancard', // bay first choice
        },
      },
      colorBnn,
      isOutStock: false,
      loadingPayment: false,
      outSockMessage: '',
      branchTypes,
      branchId: null,
      checkReceiveBranch: false,
      scbQrcodeDialog: false,
      showWarningDialog: false,
      confirmDialog: false,
      deeplink: '',
      dataForm: {},

      form: {
        // id_card: localStorage.getItem('idCard') || '',
        isOutStock: false,
        product_id: null,
        branch_time_id: undefined,
        bundle_products: [],
        payment_type_id: null,
        is_delivery: true,
        delivery: undefined,

        first_name: '',
        last_name: '',
        customer_identity: '',
        phone: '',
        email: '',
        address: '',
        sub_district: '',
        district: '',
        branch_province: '',
        province: '',
        postcode: '',
        need_true_phone: false,
        checkReceipt: false,
        checkTaxPersonal: false,
        checkTaxJuristic: false,
        taxInvoiceType: 'PERSONAL', //  'PERSONAL', 'CORPORATION'
        tax_id: '',
        taxFirstName: '',
        taxLastName: '',
        taxCompanyName: '',
        taxCompanyCode: '',
        taxAddress1: '',
        taxAddress2: '',
        taxAddress3: '',
        taxProvince: '',
        taxDistrict: '',
        taxSubDistrict: '',
        taxPostcode: '',
        taxPhoneNumber: '',
        billing_address: undefined,

        // other
        for_work: false,

        // additional_description
        additional_description: {
          brand: '',
          model: '',
          interest_item: '',
        },

        /**
         * dev
         */
        // id_card: '1231231231231',
        // product_id: null,
        // branch_time_id: '',
        // bundle_products: [],
        // payment_type_id: null,
        // is_delivery: false,

        // first_name: '123123',
        // last_name: '12312',
        // phone: '12312',
        // email: 'aa@aaa.com',
        // address: '123123',
        // sub_district: 'คลองสาน',
        // district: 'คลองสาน',
        // province: 'กรุงเทพมหานคร',
        // postcode: '12323',

        // // other
        // for_work: false,
      },

      errorIdCardFormat: false,
      errorTaxIdFormat: false,

      sameAsDeliveryAddress: true, // tax address same as delivery address
      customerIdentityType: 'id_card', // id_card, passport
      checkTaxPersonalType: 'id_card', // id_card, passport

      P_KBANK_FULL: P_KBANK_FULL,
      P_KBANK_INSTALLMENT: P_KBANK_INSTALLMENT,
      P_KBANK_DEPOSIT: P_KBANK_DEPOSIT,
      P_KRUNGSRI_DEPOSIT: P_KRUNGSRI_DEPOSIT,
      P_2C2P_DEPOSIT: P_2C2P_DEPOSIT,
      P_KRUNGSRI_FULL: P_KRUNGSRI_FULL,
      P_2C2P_FULL: P_2C2P_FULL,
      P_KRUNGSRI_INSTALLMENT: P_KRUNGSRI_INSTALLMENT,
      P_2C2P_INSTALLMENT: P_2C2P_INSTALLMENT,
      P_2C2P_UOB_INSTALLMENT: P_2C2P_UOB_INSTALLMENT,
      P_KRUNGSRI_FIRST_CHOICE_INSTALLMENT: P_KRUNGSRI_FIRST_CHOICE_INSTALLMENT,
      P_2C2P_CITYBANK_INSTALLMENT: P_2C2P_CITYBANK_INSTALLMENT,
      P_SCBEASY_FULL: P_SCBEASY_FULL,
      P_SCBEASY_CREDIT_FULL: P_SCBEASY_CREDIT_FULL,
      P_SCBEASY_DEPOSIT: P_SCBEASY_DEPOSIT,
      P_SCBEASY_CREDIT_DEPOSIT: P_SCBEASY_CREDIT_DEPOSIT,
      P_SCBEASY_INSTALLMENT: P_SCBEASY_INSTALLMENT,
      P_KBANK_QR_DEPOSIT: P_KBANK_QR_DEPOSIT,
      P_KBANK_QR_FULL: P_KBANK_QR_FULL,
      P_KTC_REDIRECT: P_KTC_REDIRECT,
      P_KTC_INSTALLMENT: P_KTC_INSTALLMENT,
      P_2C2P_QR_REDIRECT: P_2C2P_QR_REDIRECT,
      P_PAY_AT_STORE: P_PAY_AT_STORE,
    }
  },

  validations: {
    form: {
      // id_card: {
      //   required,
      //   minLength: minLength(6),
      //   maxLength: maxLength(20),
      // },
      first_name: { required, maxLength: maxLength(40) },
      last_name: { required, maxLength: maxLength(40) },
      customer_identity: {
        required,
        maxLength: maxLength(17),
        valid: function(value) {
          if (this.customerIdentityType === 'id_card') {
            return validateIdCard(value)
          } else if (this.customerIdentityType === 'passport') {
            return validatePassport(value)
          }
          return true
        },
      },
      phone: { required, numeric, maxLength: maxLength(20) },
      email: { required, email, maxLength: maxLength(40) },
      address: { required, maxLength: maxLength(100) },
      postcode: { required, maxLength: maxLength(5) },
      province: {
        required,
        maxLength: maxLength(100),
        valid: function(value) {
          return this.provinceDataForDelivery.some(
            item => item.province === value,
          )
        },
      },
      district: {
        required,
        maxLength: maxLength(100),
        valid: function(value) {
          return this.districtData.some(item => item.amphoe === value)
        },
      },
      sub_district: {
        required,
        maxLength: maxLength(100),
        valid: function(value) {
          return this.subDistrictData.some(
            item => item.district === value,
          )
        },
      },
      delivery: { required },
      for_work: { required },
      branch_province: {
        required: requiredIf(function() {
          return this.isStore
        }),
      },
      branch_time_id: {
        required: requiredIf(function() {
          return this.isStore
        }),
      },
      taxInvoiceType: {
        required: requiredIf(function() {
          return this.form.checkReceipt
        }),
      },
      tax_id: {
        required: requiredIf(function() {
          return this.form.checkReceipt
        }),
        valid: function(value) {
          if (this.form.checkReceipt) {
            if (this.checkTaxPersonal) {
              if (this.checkTaxPersonalType === 'passport') {
                return validatePassport(value)
              }

              return validateIdCard(value)
            } else if (this.checkTaxJuristic) {
              return validateStartWithZero(value)
            }
          }
          return true
        },
      },
      taxFirstName: {
        required: requiredIf(function() {
          return this.form.checkReceipt && this.checkTaxPersonal
        }),
      },
      taxLastName: {
        required: requiredIf(function() {
          return this.form.checkReceipt && this.checkTaxPersonal
        }),
      },
      taxCompanyName: {
        required: requiredIf(function() {
          return this.form.checkReceipt && this.checkTaxJuristic
        }),
      },
      taxCompanyCode: {
        required: requiredIf(function() {
          //   return this.form.checkReceipt && this.checkTaxJuristic
          return false
        }),
      },
      taxAddress1: {
        required: requiredIf(function() {
          return this.form.checkReceipt && !this.sameAsDeliveryAddress
        }),
      },
      taxAddress3: {
        required: requiredIf(function() {
          //   return this.form.checkReceipt && !this.sameAsDeliveryAddress

          return false
        }),
      },
      taxProvince: {
        required: requiredIf(function() {
          return this.form.checkReceipt && !this.sameAsDeliveryAddress
        }),
        valid: function(value) {
          return this.form.checkReceipt && !this.sameAsDeliveryAddress
            ? this.provinceData.some(item => item.province === value)
            : true
        },
      },
      taxDistrict: {
        required: requiredIf(function() {
          return this.form.checkReceipt && !this.sameAsDeliveryAddress
        }),
        valid: function(value) {
          return this.form.checkReceipt && !this.sameAsDeliveryAddress
            ? this.taxDistrictData.some(item => item.amphoe === value)
            : true
        },
      },
      taxSubDistrict: {
        required: requiredIf(function() {
          return this.form.checkReceipt && !this.sameAsDeliveryAddress
        }),
        valid: function(value) {
          return this.form.checkReceipt && !this.sameAsDeliveryAddress
            ? this.taxSubDistrictData.some(
                item => item.district === value,
              )
            : true
        },
      },
      taxPostcode: {
        required: requiredIf(function() {
          return this.form.checkReceipt && !this.sameAsDeliveryAddress
        }),
      },
      taxPhoneNumber: {
        required: requiredIf(function() {
          return this.form.checkReceipt && !this.sameAsDeliveryAddress
        }),
      },
      payment_type_id: { required },

      additional_description: {
        model: {
          required: requiredIf(function() {
            return this.form.additional_description.brand
          }),
        },
      },
    },
  },

  watch: {
    selectBundleDelivery() {
      const currentDeliverySku =
        Object.keys(this.selectBundleDelivery)?.[0] ?? null
      if (
        currentDeliverySku &&
        currentDeliverySku?.toUpperCase() !== 'NOITEC00000001' &&
        this.form.province !== 'กรุงเทพมหานคร'
      ) {
        this.form.province = null
      }
    },

    'form.province'() {
      if (this.form.postcode == '') {
        this.form.sub_district = ''
        this.form.district = ''
        this.form.postcode = ''
        // localStorage.setItem('sub_district', '')
        // localStorage.setItem('district', '')
        // localStorage.setItem('postcode', '')
      }
    },
    'form.district'() {
      if (this.form.postcode == '') {
        this.form.sub_district = ''
        this.form.postcode = ''
        // localStorage.setItem('sub_district', '')
        // localStorage.setItem('postcode', '')
      }
    },
    'form.sub_district'(val, oldVal) {
      if (val != oldVal && (oldVal || this.form.postcode == '')) {
        this.form.postcode = this.zipcodeData
      }
      //   localStorage.setItem('postcode', this.zipcodeData)
    },
    'form.taxProvince'(val, oldVal) {
      if (val != oldVal && oldVal && !this.sameAsDeliveryAddress) {
        this.form.taxDistrict = ''
        this.form.taxSubDistrict = ''
        this.form.taxPostcode = ''
      }
      //   if (!this.sameAsDeliveryAddress) {
      //     this.form.taxDistrict = ''
      //     this.form.taxSubDistrict = ''
      //     this.form.taxPostcode = ''
      //     // localStorage.setItem('taxDistrict', '')
      //     // localStorage.setItem('taxSubDistrict', '')
      //     // localStorage.setItem('taxPostcode', '')
      //   }
    },
    'form.taxDistrict'(val, oldVal) {
      if (val != oldVal && oldVal && !this.sameAsDeliveryAddress) {
        this.form.taxSubDistrict = ''
        this.form.taxPostcode = ''
        // localStorage.setItem('taxSubDistrict', '')
        // localStorage.setItem('taxPostcode', '')
      }
    },
    'form.taxSubDistrict'() {
      if (!this.sameAsDeliveryAddress) {
        this.form.taxPostcode = this.taxZipcodeData
        // localStorage.setItem('taxPostcode', this.taxZipcodeData)
      }
    },
    timeRangeData(items) {
      if ((items || []).length === 1) {
        this.form.branch_time_id = items[0].id
      }
    },
    // 'form.checkReceipt'() {
    //     if (this.form.checkReceipt) {
    //       if (this.form.taxInvoiceType == 'PERSONAL') {
    //         this.form.tax_id =
    //           this.form.tax_id || this.form.customer_identity || ''
    //         this.form.taxFirstName = this.form.first_name || ''
    //         this.form.taxLastName = this.form.last_name || ''
    //       }
    //     } else {
    //       this.form.tax_id = ''
    //       this.form.taxFirstName = ''
    //       this.form.taxLastName = ''
    //     }
    // },
    checkTaxPersonal(val) {
      if (val) {
        if (this.decryptedData.taxInvoiceType === 'PERSONAL') {
          this.form.tax_id = this.decryptedData.checkReceipt
            ? this.decryptedData.tax_id
            : this.form.customer_identity
          this.form.taxFirstName =
            this.decryptedData.taxFirstName || this.form.first_name
          this.form.taxLastName =
            this.decryptedData.taxLastName || this.form.last_name

          if (!this.decryptedData.sameAsDeliveryAddress) {
            this.sameAsDeliveryAddress = this.decryptedData.sameAsDeliveryAddress
            this.form.taxAddress1 =
              this.form.taxAddress1 || this.decryptedData.taxAddress1
            this.form.taxAddress2 =
              this.form.taxAddress2 || this.decryptedData.taxAddress2
            this.form.taxAddress3 =
              this.form.taxAddress3 || this.decryptedData.taxAddress3
            this.form.taxSubDistrict =
              this.form.taxSubDistrict ||
              this.decryptedData.taxSubDistrict
            this.form.taxDistrict =
              this.form.taxDistrict || this.decryptedData.taxDistrict
            this.form.taxProvince =
              this.form.taxProvince || this.decryptedData.taxProvince
            this.form.taxPostcode =
              this.form.taxPostcode || this.decryptedData.taxPostcode
            this.form.taxPhoneNumber =
              this.form.taxPhoneNumber ||
              this.decryptedData.taxPhoneNumber
          } else {
            this.sameAsDeliveryAddress = true
            this.form.taxAddress1 = ''
            this.form.taxAddress2 = ''
            this.form.taxAddress3 = ''
            this.form.taxSubDistrict = ''
            this.form.taxDistrict = ''
            this.form.taxProvince = ''
            this.form.taxPostcode = ''
            this.form.taxPhoneNumber = ''
          }
        } else {
          this.form.tax_id = this.form.customer_identity
          this.form.taxFirstName =
            this.form.taxFirstName || this.form.first_name
          this.form.taxLastName =
            this.form.taxLastName || this.form.last_name
          this.sameAsDeliveryAddress = true
          this.form.taxAddress1 = ''
          this.form.taxAddress2 = ''
          this.form.taxAddress3 = ''
          this.form.taxSubDistrict = ''
          this.form.taxDistrict = ''
          this.form.taxProvince = ''
          this.form.taxPostcode = ''
          this.form.taxPhoneNumber = ''
        }
      }
    },
    checkTaxJuristic(val) {
      if (val) {
        this.sameAsDeliveryAddress = false

        if (this.decryptedData.taxInvoiceType === 'CORPORATION') {
          this.form.tax_id = this.decryptedData.tax_id || ''
          this.form.taxCompanyName =
            this.form.taxCompanyName ||
            this.decryptedData.taxCompanyName
          this.form.taxCompanyCode =
            this.form.taxCompanyCode ||
            this.decryptedData.taxCompanyCode
        } else {
          if (!this.decryptedData.taxCompanyName) {
            this.form.tax_id = ''
            this.form.taxCompanyName = ''
            this.form.taxCompanyCode = ''
          } else {
            this.form.tax_id = this.decryptedData.tax_id || ''
            this.form.taxCompanyName =
              this.decryptedData.taxCompanyName || ''
            this.form.taxCompanyCode =
              this.decryptedData.taxCompanyCode || ''
          }
        }

        this.form.taxAddress1 =
          this.form.taxAddress1 || this.decryptedData.taxAddress1
        this.form.taxAddress2 =
          this.form.taxAddress2 || this.decryptedData.taxAddress2
        this.form.taxAddress3 =
          this.form.taxAddress3 || this.decryptedData.taxAddress3
        this.form.taxProvince =
          this.form.taxProvince || this.decryptedData.taxProvince
        this.form.taxDistrict =
          this.form.taxDistrict || this.decryptedData.taxDistrict
        this.form.taxSubDistrict =
          this.form.taxSubDistrict ||
          this.decryptedData.taxSubDistrict
        this.form.taxPostcode =
          this.form.taxPostcode || this.decryptedData.taxPostcode
        this.form.taxPhoneNumber =
          this.form.taxPhoneNumber ||
          this.decryptedData.taxPhoneNumber
      }
    },
  },

  mounted() {
    // this.$store.dispatch('branchStore/getBranch')
    // this.$store.dispatch('branchStore/getBranchAllType')
    this.prepareDataForm()
  },

  methods: {
    async prepareDataForm() {
      await this.$store.dispatch('user/fetchData')

      this.form = {
        ...this.form,
        ...this.decryptedData,
      }

      this.sameAsDeliveryAddress = this.decryptedData.sameAsDeliveryAddress
      this.customerIdentityType =
        this.decryptedData.customerIdentityType ?? 'id_card'
      this.checkTaxPersonalType =
        this.decryptedData.checkTaxPersonalType ?? 'id_card'

      // Auto replace format customer_identity
      if (this.form.customer_identity) {
        this.form.customer_identity = this.form.customer_identity.replace(
          /(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/,
          '$1-$2-$3-$4-$5',
        )
      }

      // Set default value
      // Default value for tax personal
      // Set default value
      if (!this.decryptedData.checkReceipt) {
        // data checkReceipt is false
        // Default value for tax personal
        this.form.tax_id = this.form.customer_identity
        this.form.taxFirstName = this.form.first_name
        this.form.taxLastName = this.form.last_name
        this.sameAsDeliveryAddress = true
      } else {
        this.form.tax_id =
          this.decryptedData.tax_id || this.form.customer_identity
        this.form.taxFirstName =
          this.decryptedData.taxFirstName || this.form.first_name
        this.form.taxLastName =
          this.decryptedData.taxLastName || this.form.last_name
      }
      // ------
    },
    memberLogout() {
      localStorage.removeItem('registered')
      localStorage.removeItem('isMember')

      this.$router.push({
        name: 'ProductDetail',
        params: { slug: this.$route.params?.slug },
        query: {
          ...this.$route.query,
        },
      })
    },
    openSCB() {
      window.open(this.deeplink, '_blank')
    },

    onFocusAutoComplete(value) {
      this.$store.dispatch('cart/showSummary', value)
      this.$forceUpdate()
    },

    onFocus() {
      // this.$store.dispatch('cart/showSummary', value)
      // this.$forceUpdate()
    },

    persist(key, value) {
      localStorage.setItem(key, value)
    },
    isNumber(evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    selectPayment() {
      // this.paymentId = value
      this.prepare()
      this.$v.form.payment_type_id.$touch()
    },
    onSelectDelivery() {
      this.prepare()
    },
    prepare() {
      // add product data
      let bundleSelect = []
      this.bundles?.forEach(i => {
        let has = false
        if (this.bundleItems.find(b => b.sku === i.sku)) {
          has = true
        }
        if (has) {
          bundleSelect.push(
            this.bundleItems.filter(b => b.sku === i.sku)?.[0],
          )
        }
      })

      let additionProductSelect = []
      this.additionalProduct?.forEach(i => {
        let has = false
        if (this.bundleItems.find(b => b?.sku === i?.sku)) {
          has = true
        }
        if (has) {
          additionProductSelect.push(
            this.bundleItems.filter(b => b?.sku === i?.sku)?.[0],
          )
        }
      })

      let appleCareSelect = []
      let has = false
      if (
        this.bundleItems.find(b => b?.sku === this.appleCare?.sku)
      ) {
        has = true
      }

      if (has) {
        appleCareSelect.push(
          this.bundleItems.filter(
            b => b?.sku === this.appleCare?.sku,
          )?.[0],
        )
      }

      let accessoryBundlesSelect = []
      this.accessoryBundles?.forEach(i => {
        let has = false
        if (this.bundleItems.find(b => b?.sku === i?.sku)) {
          has = true
        }
        if (has) {
          accessoryBundlesSelect.push(
            this.bundleItems.filter(b => b?.sku === i?.sku)?.[0],
          )
        }
      })

      this.form.product_id = this.productItem.id
      this.form.need_true_phone = this.customFieldOnCart
        ? this.form.need_true_phone
        : undefined
      this.form.bundle_products = [
        ...bundleSelect,
        ...additionProductSelect,
        ...appleCareSelect,
        ...accessoryBundlesSelect,
        ...this.deliveryItems,
      ]
      // this.form.delivery = this.isDeposit
      //   ? false
      //   : this.selectDelivery
      // this.form.is_delivery = !this.isDeposit

      this.form.delivery =
        !this.isStore && !this.isDelivery ? undefined : !this.isStore
      this.form.is_delivery = !this.isStore
      // this.form.is_delivery = this.delivery
      this.form.payment_type_id = this.paymentId

      // add tax address when sameAsDeliveryAddress is true
      this.setAddressSameBillingAddress()
    },

    setAddressSameBillingAddress() {
      if (this.sameAsDeliveryAddress) {
        this.form.taxAddress1 = this.form.address
        this.form.taxAddress2 = this.form.address2
        this.form.taxAddress3 = this.form.address3
        this.form.taxProvince = this.form.province
        this.form.taxDistrict = this.form.district
        this.form.taxSubDistrict = this.form.sub_district
        this.form.taxPostcode = this.form.postcode
        this.form.taxPhoneNumber = this.form.phone
      } else if (!this.form.checkReceipt) {
        this.form.taxAddress1 = ''
        this.form.taxAddress2 = ''
        this.form.taxAddress3 = ''
        this.form.taxCompanyCode = ''
        this.form.taxCompanyName = ''
        this.form.taxDistrict = ''
        this.form.taxFirstName = ''
        this.form.taxInvoiceType = ''
        this.form.taxLastName = ''
        this.form.taxPhoneNumber = ''
        this.form.taxPostcode = ''
        this.form.taxProvince = ''
        this.form.taxSubDistrict = ''
      }
    },

    async onSubmit() {
      await this.submit()
    },

    async onClickCloseOutStock() {
      this.isOutStock = false
      await this.$store.dispatch('model/getModel', true)
      await this.$store.dispatch('model/reset')
      await this.$store.dispatch('bundle/reset')
      window.scrollTo({
        top: 0,
      })
    },

    async submit() {
      this.prepare()

      this.$v.$touch()
      if (this.$v.$invalid) {
        for (let key in Object.keys(this.$v.form)) {
          const input = Object.keys(this.$v.form)[key]
          if (input.includes('$')) return false
          if (this.$v.form[input].$error) {
            this.autoFocusField(input)
            break
          }
        }
      }
      if (this.$v.$error) {
        //  console.log('error')
        return
      }

      // Update data before submit
      const updateDataForm = {
        ...this.form,
        sameAsDeliveryAddress: this.sameAsDeliveryAddress,
        customerIdentityType: this.customerIdentityType,
        checkTaxPersonalType: this.checkTaxPersonalType,
      }

      this.$store.dispatch('user/setData', updateDataForm)

      if (
        this.samsungHandraiser &&
        !this.coupon.code &&
        !this.confirmDialog
      ) {
        this.showWarningDialog = true
        return
      } else if (
        this.confirmDialog ||
        this.coupon.code ||
        !this.samsungHandraiser
      ) {
        this.showWarningDialog = false
        this.confirmDialog = false

        this.loadingPayment = true

        try {
          this.outSockMessage = ''
          const res = await this.doCreateOrder()
          const data = res.data

          switch (this.paymentId) {
            case P_2C2P_DEPOSIT:
            case P_2C2P_FULL:
            case P_2C2P_INSTALLMENT:
            case P_2C2P_UOB_INSTALLMENT:
            case P_2C2P_CITYBANK_INSTALLMENT:
            case P_2C2P_QR_REDIRECT:
              await this.doPayWith2C2P(data.uuid)
              break
            case P_KBANK_DEPOSIT:
            case P_KBANK_INSTALLMENT:
            case P_KBANK_FULL:
              await this.doPayWithKBank(data.uuid)
              break
            case P_KRUNGSRI_DEPOSIT:
            case P_KRUNGSRI_FULL:
            case P_KRUNGSRI_INSTALLMENT:
            case P_KRUNGSRI_FIRST_CHOICE_INSTALLMENT:
              await this.doPayWithBay(data.uuid)
              break

            case P_SCBEASY_FULL:
            case P_SCBEASY_CREDIT_FULL:
            case P_SCBEASY_DEPOSIT:
            case P_SCBEASY_CREDIT_DEPOSIT:
            case P_SCBEASY_INSTALLMENT:
              await this.doPayWithSCB(data.uuid)
              break

            case P_KBANK_QR_DEPOSIT:
            case P_KBANK_QR_FULL:
              await this.doPayWithKBankQrCode(data.uuid)
              break

            case P_KTC_REDIRECT:
              await this.doPayWithKTC(data.uuid)
              break

            case P_KTC_INSTALLMENT:
              await this.doPayWithKTC(data.uuid)
              break

            case P_PAY_AT_STORE:
              this.doPayAtStore(data.uuid)
              break

            // todo scbeasy
          }

          setTimeout(() => {
            this.loadingPayment = false
          }, 5000)
        } catch (err) {
          //   console.log(err)
          // setTimeout(() => {
          //   this.$emit('loading', false)
          // }, 5000)
          // this.$store.dispatch('branchStore/getRefetchBranch')
          if (err?.response?.data?.message.includes(`หมด`)) {
            this.isOutStock = true
            this.outSockMessage = err?.response?.data?.message
            ///// Reset ////
            await this.$store.dispatch('model/getModel', true)
            // await this.$store.dispatch('model/reset')
            // await this.$store.dispatch('bundle/reset')
            window.scrollTo({
              top: 0,
            })
            ///// Reset ////
          } else {
            if (
              err?.response?.data?.message?.indexOf(`ได้ครบจำนวน`) >
              -1
            ) {
              this.$store.dispatch('branchStore/getRefetchBranch')
            }

            alert(
              err?.response?.data?.message ||
                'ขออภัยในความไม่สะดวก กรุณาทำรายการใหม่อีกครั้ง',
            )
          }

          this.loadingPayment = false
          return
        }
      }
    },

    doCreateOrder() {
      let coupon_code = undefined
      coupon_code =
        this.coupon.code && !this.coupon.isError
          ? this.coupon.code
          : undefined
      // if (this.branchType !== this.branchTypes.BRANCH_TYPE_DEPOSIT) {
      //   coupon_code =
      //     this.coupon.code && !this.coupon.isError
      //       ? this.coupon.code
      //       : undefined
      // }

      const queryParam = this.$route?.query
      const utm = {
        id: queryParam?.utm_id,
        source: queryParam?.utm_source ?? null,
        medium: queryParam?.utm_medium ?? null,
        campaign: queryParam?.utm_campaign ?? null,
        content: queryParam?.utm_content ?? null,
        term: queryParam?.utm_term ?? null,
      }

      const admin_quota = queryParam?.admin_quota
        ? queryParam?.admin_quota.toLowerCase() === 'true'
        : false

      const is_stockcard = queryParam?.admin_quota ? true : false

      const sc = queryParam?.sc ?? null
      const sbc = queryParam?.branch_code ?? null
      const code = queryParam?.code ?? null

      return axios
        .post('/orders', {
          ...this.form,
          branch_time_id: this.isStore
            ? this.form.branch_time_id
            : undefined,
          recaptcha_token: this.isVerifyRecaptcha
            ? this.recaptchaKey
            : undefined,
          billing_address: this.billingAddress(),
          coupon_code,
          utm,
          admin_quota,
          is_stockcard,
          sc,
          sbc,
          code,
        })
        .then(res => {
          this.$store.commit('recaptcha/setReset', true)

          setTimeout(() => {
            this.$store.commit('recaptcha/setReset', false)
          }, 3000)
          return res.data
        })
        .catch(e => {
          alert(e.response.data.message)
        })
    },

    async doPayWith2C2P(orderId) {
      const params = {
        order_id: orderId,
        html: true,
      }

      const body = await axios
        .post('/payments/2c2p', params)
        .then(res => res.data)

      // put html to body
      const el = document.createElement('div')
      el.innerHTML = body
      document.body.appendChild(el)

      // click
      const form = document.getElementById('myform')
      form.style = 'display:none'
      typeof form.submit === 'function'
        ? form.submit()
        : form.submit.click()
    },

    async doPayWithBay(orderId) {
      const params = {
        order_id: orderId,
        html: true,
        term:
          this.installmentPlanSelected?.terms ??
          this.paymentOptions?.[`payment${this.paymentId}`]?.term ??
          null,
        interest: this.installmentPlanSelected?.interest ?? 0,
        card_type:
          this.paymentOptions?.[`payment${this.paymentId}`]
            ?.cardType ?? null,
      }

      const body = await axios
        .post('/payments/krungsri', params)
        .then(res => res.data)

      // put html to body
      const el = document.createElement('div')
      el.innerHTML = body
      document.body.appendChild(el)

      // click
      const form = document.getElementById('myform')
      form.style = 'display:none'
      typeof form.submit === 'function'
        ? form.submit()
        : form.submit.click()
    },

    async doPayWithSCB(orderId) {
      const params = {
        order_id: orderId,
      }
      const body = await axios
        .post('/payments/scb-easy', params)
        .then(res => res.data)
      this.deeplink = body.data.deeplinkUrl
      this.scbQrcodeDialog = true

      const urlParams = new URL(this.deeplink)?.searchParams
      const callbackUrl = urlParams.get('callback_url')

      const refetchSCBCallBack = setInterval(async () => {
        const result = await axios
          .get(`/orders/${orderId}`)
          .then(res => res.data)

        if (
          result.data.status !== 'waiting' &&
          result.data.status !== 'create'
        ) {
          clearInterval(refetchSCBCallBack)
          this.scbQrcodeDialog = false
          if (
            result.data.status !== 'cancel' ||
            result.data.status !== 'return'
          ) {
            if (callbackUrl) {
              window.location.href = callbackUrl
            } else {
              this.$router.push({
                name: 'Thank',
                params: {
                  uid: orderId,
                },
              })
            }
          }
        }
      }, 10000)

      // Clear interval 15 mins
      setTimeout(() => {
        clearInterval(refetchSCBCallBack)
      }, 1000 * 60 * 15)
    },

    async doPayWithKBank(orderId) {
      const params = {
        order_id: orderId,
        //html: 0,
        smartpay: this.form.payment_type_id < 5 ? 0 : 1,
      }

      const body = await axios
        .post('/payments/kbank', params)
        .then(res => res.data)

      const embeds = {
        ref_id: body?.data.ref_id,
        merchant_id: body?.data.merchant_id,
        apiKey: body?.data.public_apikey,
        amount: body?.data.amount,
        currency: body?.data.currency,
        smartpayId: body?.data.smartpay_id,
        smartpayTerm: body?.data.smartpay_term,
        scriptUrl: body?.data.script_url,
        endpoint: body?.data.action_url,
      }
      showKBankEmbedUI(embeds)
    },

    async doPayWithKBankQrCode(orderId) {
      const params = {
        order_id: orderId,
        //html: 0,
      }

      const body = await axios
        .post('/payments/kbank-qr', params)
        .then(res => res.data)

      const embeds = {
        // for required field
        ref_id: body?.data.ref_id,
        apiKey: body?.data.public_apikey,
        amount: body?.data.amount,
        paymentMethods: body?.data.method,
        orderId: body?.data.order_id,
        scriptUrl: body?.data.script_url,
        // end required field

        // for optional field
        endpoint: body?.data.action_url,
        // end optional field
      }
      showKBankEmbedUI(embeds)
    },

    async doPayWithKTC(orderId) {
      const params = {
        order_id: orderId,
        html: true,
      }

      const body = await axios
        .post('/payments/ktc', params)
        .then(res => res.data)

      // put html to body
      const el = document.createElement('div')
      el.innerHTML = body
      document.body.appendChild(el)

      // click
      const form = document.getElementById('myform')
      form.style = 'display:none'
      typeof form.submit === 'function'
        ? form.submit()
        : form.submit.click()
    },

    async doPayAtStore(orderId) {
      const { t } = this.$route.query

      if (t == STOCK_CARD_TYPE.STORE && this.isLoggedIn) {
        await this.$router.push({
          name: 'ConfirmPayment',
          params: {
            uid: orderId,
          },
        })
      } else {
        await this.$router.push({
          name: 'Thank',
          params: {
            uid: orderId,
          },
        })
      }
    },

    // checkTax(value) {
    //   if (value === 0) {
    //     this.form.checkReceipt = !this.form.checkReceipt
    //     if (this.form.checkReceipt) {
    //       this.form.checkTaxPersonal = true
    //     } else {
    //       this.form.checkTaxPersonal = false
    //       this.form.checkTaxJuristic = false
    //     }
    //   } else if (value === 1) {
    //     this.form.checkTaxPersonal = !this.form.checkTaxPersonal
    //     this.form.checkTaxJuristic = false
    //   } else if (value === 2) {
    //     this.form.checkTaxJuristic = !this.form.checkTaxJuristic
    //     this.form.checkTaxPersonal = false
    //   }
    // },

    checkSameAsDeliveryAddress() {
      if (this.sameAsDeliveryAddress) {
        this.setTaxFullName()
        return false
      } else {
        return true
      }
    },

    setTaxFullName() {
      if (this.checkTaxPersonal) {
        this.form.taxFirstName = this.form.first_name
        this.form.taxLastName = this.form.last_name
      } else {
        this.form.taxFirstName = ''
        this.form.taxLastName = ''
      }
    },

    checkNeedTruePhone() {
      this.form.need_true_phone = !this.form.need_true_phone
    },

    strHaveBR(str) {
      // return str.match(/(<br>|<br\/>)/)
      return str
    },

    resetBranchTime() {
      this.form.branch_time_id = null
    },

    billingAddress() {
      // let taxInvoiceType = ''
      // let is_tax_invoice = false
      // if (this.form.checkTaxPersonal) taxInvoiceType = 'PERSONAL'
      // if (this.form.checkTaxJuristic) taxInvoiceType = 'CORPORATION'
      // is_tax_invoice =
      //   this.form.checkReceipt ||
      //   this.form.checkTaxPersonal ||
      //   this.form.checkTaxJuristic
      const payload = {
        is_tax_invoice: this.form.checkReceipt,
        tax_invoice_type: this.form.taxInvoiceType,
        tax_id:
          this.checkTaxPersonal || this.checkTaxJuristic
            ? this.form.tax_id
            : undefined,
        company_name: this.checkTaxJuristic
          ? this.form.taxCompanyName
          : undefined,
        company_code: this.checkTaxJuristic
          ? this.form.taxCompanyCode
          : undefined,
        first_name: !this.checkTaxJuristic
          ? this.form.taxFirstName
          : undefined,
        last_name: !this.checkTaxJuristic
          ? this.form.taxLastName
          : undefined,
        address: `${this.form.taxAddress1 || ''} ${this.form
          .taxAddress2 || ''} ${this.form.taxAddress3 || ''}`,
        sub_district: this.form.taxSubDistrict,
        district: this.form.taxDistrict,
        province: this.form.taxProvince,
        country: 'ไทย',
        postcode: this.form.taxPostcode,
        phone: this.form.taxPhoneNumber,
      }
      return this.form.checkReceipt ? payload : undefined
    },

    isMobile() {
      const check = /iPhone|iPad|iPod|Android/i.test(
        navigator.userAgent,
      )
      return check || this.isIpadPro() ? true : false
    },

    isIpadPro() {
      return (
        /MacIntel/.test(navigator.platform) &&
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2
      )
    },

    onBlurAutoComplete() {
      if (this.form.postcode) {
        let filteredLocations = this.$store.getters[
          'thailandStore/getAll'
        ].filter(location => location.zipcode == this.form.postcode)

        if (filteredLocations.length > 0) {
          this.form.province = filteredLocations[0].province
          this.form.district = filteredLocations[0].amphoe
          this.form.sub_district = filteredLocations[0].district

          //   localStorage.setItem(
          //     'province',
          //     filteredLocations[0].province,
          //   )
          //   localStorage.setItem(
          //     'district',
          //     filteredLocations[0].amphoe,
          //   )
        }
      }
    },

    autoFormatIdCard(value, feild) {
      // Remove all non-digit characters
      let cleaned = value.slice(0, 17).replace(/[^\d]+/g, '')

      // Format the cleaned string
      let match = cleaned.match(
        /^(\d{0,1})(\d{0,4})(\d{0,5})(\d{0,2})(\d{0,1})/,
      )

      if (match) {
        cleaned = `${match[1] || ''}${
          match[2] ? '-' + match[2] : ''
        }${match[3] ? '-' + match[3] : ''}${
          match[4] ? '-' + match[4] : ''
        }${match[5] ? '-' + match[5] : ''}`
      }

      //   Update the input value
      this.form[feild] = cleaned
    },

    autoFocusField(field) {
      var element = document.querySelector(`[data-pptr='${field}']`)

      if (!element) {
        return
      }

      element?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })

      if (this?.$refs[field]?.focus) {
        setTimeout(() => this?.$refs[field]?.focus(), 300)
      }
    },

    handleErrorCustomerIdentity(input) {
      if (this.$v.form[input].$error) {
        if (!this.$v.form[input].required) {
          return ''
        } else if (this.$v.form[input].$invalid) {
          if (
            (input === 'customer_identity' &&
              this.customerIdentityType === 'passport') ||
            (input === 'tax_id' &&
              this.checkTaxPersonalType === 'passport')
          ) {
            return 'Please enter a valid passport number.'
          }
          return input === 'tax_id'
            ? 'กรุณากรอกเลขประจำตัวผู้เสียภาษีให้ถูกต้อง'
            : 'กรุณากรอกเลขบัตรประชาชนให้ถูกต้อง'
        }
      }
      return ''
    },
  },
}
</script>

<style lang="stylus">

.customer-form
    background $color-bnn-gray
    padding-top 1rem
    padding-bottom 3rem
    margin-top 7rem
    width 100%
    @media screen and (max-width: 960px)
      margin-top 4rem
.form-customer-layout
    width 100%
    @media medium
      width 100%
      padding 0
    @media small
      width 100%
      padding 0
.v-text-field.v-text-field--enclosed .v-text-field__details
  margin-bottom 0 !important
.v-messages.theme--light
  min-height 0px !important
.v-input--selection-controls
  margin-top 0px !important
.input-transparent
  background: transparent !important;
  border-color: transparent !important;
.branch-time
  display flex
  justify-content center
  align-items center
  margin-left -12px
  margin-right -12px
  .branch
    width 50%
    padding: 12px
    @media screen and (max-width: 960px)
      width 100%
  .time
     width 50%
     padding: 12px
     @media screen and (max-width: 960px)
       width 100%
   @media screen and (max-width: 960px)
    flex-direction column
</style>

<style lang="stylus" scoped>
::v-deep .v-input__slot {
  background: transparent !important;
  border-color: transparent !important;
}

.scb-easy-modal
  background: transparent linear-gradient(180deg, #A700FF 0%, #5500B9 100%) 0% 0% no-repeat padding-box;
.scb-card-qrcode
  background white
  border-radius: 8px;
  border: 1px solid #00000000;
  padding: 1.5rem
.button-back-scb
  border-color: white !important

.is-desktop
  display: block
  @media tablet
    display: none
.note-form-address
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center
  margin-top: -32px

  font-size: 0.875rem

  > div {
	text-align: center
  }

  @media tablet
    font-size: 0.875rem

  @media small
    font-size: 11px



.warning-tax-invoice
  width: 100%
  padding: 8px
  border: 1px solid #ccc
  border-radius: 8px
  font-size: 14px
  margin-top: 8px

.warning-tax-invoice-title
  font-size: 15px
  font-weight: bold

.text-alert
  color: $color-red

.text-black
  color: $color-bnn-gray-medium

::v-deep {
    .input-compact-container {
        &:first-child {
            .input-compact {
                border-radius: 5px 0 0 5px !important;

                &:not(.v-input--is-focused) {
                    fieldset {
                        border-right: none !important;
                    }
                }
            }


        }
        &:last-child {
            .input-compact {
                border-radius: 0 5px 5px 0 !important;
            }
        }
    }

    .no-border-shadow .v-input__control {
        border: none !important;
        box-shadow: none !important;
    }
}
</style>
