<template>
  <div class="payment px-lg-4 px-0">
    <!-- <SummaryCart :isShowDetailPrice="true" :isShowAction="false" /> -->
    <div class="p-layout">
      <div class="payment-content-2 mt-3">
        <div class="mt-8">
          <div>
            <template v-if="isDeposit">
              <!-- Note: Hide for deposit payment -->
              <!-- <template v-if="isShowPayAtStoreMethod">
                <h4 class="text-lg payment-label">
                  ชำระเงินที่สาขา
                </h4>
                <div class="chanel-payment-grid">
                  <ButtonPayment
                    v-for="item in payAtStoreList"
                    :key="item.id"
                    :hasError="isErrorRequirePayment"
                    :name="item.title"
                    :image="iconBank[item.id]"
                    :selected="paymentId === item.id"
                    :label="item.short_description"
                    data-pptr="payment_type_id"
                    @click="onSelectPaymentMethod(item)"
                  />
                </div>
              </template> -->
              <h4 class="text-lg payment-label">
                ชำระเงินแบบมัดจำ
              </h4>
              <div class="chanel-payment-grid">
                <ButtonPayment
                  v-for="item in depositList"
                  :key="item.id"
                  :hasError="isErrorRequirePayment"
                  :name="item.title"
                  :label="item.short_description"
                  :image="iconBank[item.id]"
                  :selected="paymentId === item.id"
                  data-pptr="payment_type_id"
                  @click="onSelectPaymentMethod(item)"
                />
              </div>
              <template v-if="appListDeposit.length > 0">
                <h4 class="text-lg payment-label">
                  ชำระผ่านแอพพลิเคชั่น
                </h4>
                <div class="chanel-payment-grid">
                  <ButtonPayment
                    v-for="item in appListDeposit"
                    :key="item.id"
                    :hasError="isErrorRequirePayment"
                    :name="item.title"
                    :image="iconBank[item.id]"
                    :selected="paymentId === item.id"
                    :label="item.short_description"
                    data-pptr="payment_type_id"
                    @click="onSelectPaymentMethod(item)"
                  />
                </div>
              </template>
            </template>

            <template v-else>
              <template v-if="isShowPayAtStoreMethod">
                <h4 class="text-lg payment-label">
                  ชำระเงินที่สาขา
                </h4>
                <div class="chanel-payment-grid">
                  <ButtonPayment
                    v-for="item in payAtStoreList"
                    :key="item.id"
                    :hasError="isErrorRequirePayment"
                    :name="item.title"
                    :image="iconBank[item.id]"
                    :selected="paymentId === item.id"
                    :label="item.short_description"
                    data-pptr="payment_type_id"
                    @click="onSelectPaymentMethod(item)"
                  />
                </div>
              </template>
              <template v-if="fullPaymentList">
                <h4 class="text-lg payment-label">
                  ชำระเงินแบบเต็มจำนวน
                </h4>
                <div class="chanel-payment-grid">
                  <ButtonPayment
                    v-for="item in fullPaymentList"
                    :key="item.id"
                    :hasError="isErrorRequirePayment"
                    :name="item.title"
                    :image="iconBank[item.id]"
                    :selected="paymentId === item.id"
                    :label="item.short_description"
                    data-pptr="payment_type_id"
                    @click="onSelectPaymentMethod(item)"
                  />
                </div>
              </template>
              <template v-if="installmentList">
                <template v-if="installmentList">
                  <h4 class="text-lg payment-label">
                    ผ่อนชำระด้วยบัตรเครดิต
                  </h4>
                </template>
                <div class="chanel-payment-grid">
                  <ButtonPayment
                    v-for="item in installmentList"
                    :key="item.id"
                    :hasError="isErrorRequirePayment"
                    :name="item.title"
                    :image="iconBank[item.id]"
                    :selected="paymentId === item.id"
                    :label="item.short_description"
                    :items="JSON.parse(item.dropdown)"
                    data-pptr="payment_type_id"
                    @click="onSelectPaymentMethod(item)"
                    @updateInstallmentPlan="
                      handleUpdateInstallmentPlan
                    "
                  />
                </div>
              </template>
              <template v-if="appList.length > 0">
                <h4 class="text-lg payment-label">
                  ชำระผ่านแอพพลิเคชั่น
                </h4>
                <div class="chanel-payment-grid">
                  <ButtonPayment
                    v-for="item in appList"
                    :key="item.id"
                    :hasError="isErrorRequirePayment"
                    :name="item.title"
                    :image="iconBank[item.id]"
                    :selected="paymentId === item.id"
                    :label="item.short_description"
                    data-pptr="payment_type_id"
                    @click="onSelectPaymentMethod(item)"
                  />
                </div>
              </template>
            </template>
          </div>
          <div class="payment-label font-bold" v-if="isDeposit">
            *หากชำระเงินค่ามัดจำสินค้าเรียบร้อยแล้ว
            ท่านสามารถชำระยอดที่เหลือที่สาขาที่เลือก ตามที่ระบุใน
            Email หรือ SMS , Line
          </div>
        </div>

        <v-row
          v-if="isVerifyRecaptcha"
          v-bind="{ justify: 'center' }"
          class="mt-12"
        >
          <Recaptcha @validate="validate" />
        </v-row>
        <div ref="beforeCheckoutAction"></div>
        <div
          v-if="showSummary"
          class="checkout-action"
          :class="checkoutActionClass"
        >
          <v-container class="is-tablet">
            <div class="summary">
              <div class="price-summary-cart-3-lines">
                <div>
                  <h4 v-if="isDeposit">ยอดรวมชำระมัดจำ</h4>
                  <h4 v-else>ยอดรวมชำระเต็มจำนวน</h4>
                </div>
                <div class="text-right price-summary-text">
                  <h4>฿{{ netPriceSummary | currency2 }} .-</h4>
                </div>
              </div>
            </div>
            <hr class="my-3 gray" />
          </v-container>
          <v-container class="d-flex justify-center">
            <div class="action">
              <div class="d-flex justify-center">
                <Button
                  v-if="!isSoldOut"
                  @click="checkValid ? onSubmit() : false"
                  :disabled="disabledButton"
                  :loading="loading"
                  width="100%"
                  depressed
                  color="color-bnn"
                  title="ตรวจสอบคำสั่งซื้อและชำระเงิน"
                />
                <Button
                  v-else
                  disabled
                  :loading="loading"
                  width="100%"
                  depressed
                  color="color-bnn-opacity-90"
                  title="สินค้าหมด"
                />
              </div>
              <div class="btn-checkout-back">
                <!-- <Button
                  @click="goBack()"
                  width="100%"
                  depressed
                  color="color-bnn-gray-light-2"
                  title="ย้อนกลับ"
                /> -->
                <v-btn
                  @click.stop="goBack()"
                  block
                  outlined
                  class="btn-back-to-cus-also-bought"
                  height="4px"
                >
                  <span class="text-back-to-cus-also-bought">
                    ย้อนกลับ
                  </span>
                </v-btn>
              </div>
            </div>
          </v-container>
        </div>
      </div>
    </div>
    <!-- <CartSummary ref="cartSummary" /> -->
    <div class="footer-margin"></div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ButtonPayment from './ButtonPayment'
import Button from '@/components/bnn/Button'
// import routePage from '@/config/routePage'
import {
  P_KBANK_DEPOSIT,
  P_KRUNGSRI_DEPOSIT,
  P_KBANK_INSTALLMENT,
  P_KBANK_FULL,
  P_2C2P_DEPOSIT,
  P_KRUNGSRI_FULL,
  P_2C2P_FULL,
  P_KRUNGSRI_INSTALLMENT,
  P_2C2P_INSTALLMENT,
  P_2C2P_UOB_INSTALLMENT,
  P_KRUNGSRI_FIRST_CHOICE_INSTALLMENT,
  P_2C2P_CITYBANK_INSTALLMENT,
  P_SCBEASY_FULL,
  P_SCBEASY_CREDIT_FULL,
  P_SCBEASY_DEPOSIT,
  P_SCBEASY_CREDIT_DEPOSIT,
  P_SCBEASY_INSTALLMENT,
  P_KBANK_QR_FULL,
  P_KBANK_QR_DEPOSIT,
  P_PAY_AT_STORE,
} from '@/config/payment.json'
import iconBank from '@/config/iconBank'
import Recaptcha from '@/components/Recaptcha'
// import CartSummary from '@/components/bnn/CartSummary'
import branchTypes from '@/config/branchTypes'
import cartSummary from '@/mixins/cartSummary'
// import SummaryCart from '@/components/bnn/SummaryCart'
import { STOCK_CARD_TYPE } from '@/config/stockCard'

export default {
  name: 'Payment',

  mixins: [cartSummary],

  props: {
    loading: Boolean,
    isErrorRequirePayment: Boolean,
  },

  components: {
    ButtonPayment,
    Recaptcha,
    Button,
    // SummaryCart,
    // CartSummary,
  },

  computed: {
    ...mapState({
      branchType: state => state.branchStore.branchType,
      campaign: state => state.campaign.current,
      paymentTypeData: state => state.paymentType.data,
      paymentId: state => state.paymentType.paymentId,
      coupon: state => state.cart.coupon,
      showSummary: state => state.cart.showSummary,
      cartSummaryData: state => state.cart.cartSummaryData,
    }),
    ...mapGetters({
      //   haveDeposit: 'cart/haveDeposit',
      //   priceDeposit: 'cart/priceDeposit',
      bundles: 'model/getBundles',
      additionalProduct: 'model/getAdditionalProduct',
      appleCare: 'model/getAppleCare',
      productItem: 'cart/productItem',
      priceSummary: 'cart/priceSummary',
      isSoldOut: 'model/getSoldOut',
      deliveryItems: 'cart/deliveryItems',
      bundleItems: 'cart/bundleItems',
      //   cartItems: 'cart/cartItems',
    }),

    isDeposit() {
      return this.branchType === this.branchTypes.BRANCH_TYPE_DEPOSIT
    },

    netPriceSummary() {
      return this.cartSummaryData?.total_price ?? 0
    },

    depositList() {
      return (
        this.paymentTypeData?.filter(
          i => i?.type === 'deposit',
          // i?.name?.toUpperCase().indexOf('SCB') === -1,
        ) ?? []
      )
    },

    fullPaymentList() {
      return (
        this.paymentTypeData?.filter(
          i => i?.type === 'full-payment',
          // i?.name?.toUpperCase().indexOf('SCB') === -1,
        ) ?? []
      )
    },

    payAtStoreList() {
      return (
        this.paymentTypeData?.filter(
          i => i?.type === 'pay-at-store',
          // i?.name?.toUpperCase().indexOf('SCB') === -1,
        ) ?? []
      )
    },

    installmentList() {
      return (
        this.paymentTypeData?.filter(
          i => i?.type === 'installment',
          // i?.name?.toUpperCase().indexOf('SCB') === -1,
        ) ?? []
      )
    },

    appList() {
      // TODO: Deprecate this
      // return (
      //   this.paymentTypeData?.filter(
      //     i =>
      //       i?.type !== 'deposit' &&
      //       i?.name?.toUpperCase().indexOf('SCB') > -1,
      //   ) ?? []
      // )
      return []
    },

    appListDeposit() {
      // TODO: Deprecate this
      // return (
      //   this.paymentTypeData?.filter(
      //     i =>
      //       i?.type === 'deposit' &&
      //       i?.name?.toUpperCase().indexOf('SCB') > -1,
      //   ) ?? []
      // )
      return []
    },

    shipDate() {
      return this.campaign?.data?.shipdate
    },

    campaignId() {
      return this.campaign?.data?.id ?? ''
    },

    disabledButton() {
      if (this.isVerifyRecaptcha) {
        return !this.checkAgree || !this.validateRecaptcha
      }
      return !this.checkAgree
    },

    checkValid() {
      if (this.isVerifyRecaptcha) {
        return this.checkAgree && this.validateRecaptcha
      }
      return this.checkAgree
    },

    isVerifyRecaptcha() {
      return this.campaign?.data?.is_verify_recaptcha === 1
    },

    checkoutActionClass() {
      // TODO: Not sticky
      // get position div class cart-ship-wrapper
      // return {
      //   'checkout-action-fixed':
      //     this.scrollY > this.positionCheckoutAction,
      // }
      return {}
    },

    isShowPayAtStoreMethod() {
      const { t } = this.$route.query

      return (
        this.payAtStoreList.length > 0 &&
        (t === STOCK_CARD_TYPE.STORE || t === STOCK_CARD_TYPE.QR)
      )
    },
  },

  async mounted() {
    // await this.$store.dispatch(
    //   'paymentType/getPaymentType',
    //   this.campaignId,
    // )
    window.addEventListener('scroll', this.handleScroll)
  },

  data() {
    return {
      branchTypes,
      validateRecaptcha: false,
      checkAgree: true,
      isShowTerm: false,
      P_KBANK_FULL: P_KBANK_FULL, //
      P_KBANK_INSTALLMENT: P_KBANK_INSTALLMENT, //
      P_KBANK_DEPOSIT: P_KBANK_DEPOSIT, //
      P_KRUNGSRI_DEPOSIT: P_KRUNGSRI_DEPOSIT, //
      P_2C2P_DEPOSIT: P_2C2P_DEPOSIT, //
      P_KRUNGSRI_FULL: P_KRUNGSRI_FULL, //
      P_2C2P_FULL: P_2C2P_FULL, //
      P_KRUNGSRI_INSTALLMENT: P_KRUNGSRI_INSTALLMENT, //
      P_2C2P_INSTALLMENT: P_2C2P_INSTALLMENT, //
      P_2C2P_UOB_INSTALLMENT: P_2C2P_UOB_INSTALLMENT, //
      P_KRUNGSRI_FIRST_CHOICE_INSTALLMENT: P_KRUNGSRI_FIRST_CHOICE_INSTALLMENT, //
      P_2C2P_CITYBANK_INSTALLMENT: P_2C2P_CITYBANK_INSTALLMENT, //
      P_SCBEASY_FULL: P_SCBEASY_FULL, //
      P_SCBEASY_CREDIT_FULL: P_SCBEASY_CREDIT_FULL, //
      P_SCBEASY_DEPOSIT: P_SCBEASY_DEPOSIT, //
      P_SCBEASY_CREDIT_DEPOSIT: P_SCBEASY_CREDIT_DEPOSIT, //
      P_SCBEASY_INSTALLMENT: P_SCBEASY_INSTALLMENT, //
      P_KBANK_QR_FULL: P_KBANK_QR_FULL,
      P_KBANK_QR_DEPOSIT: P_KBANK_QR_DEPOSIT,
      P_PAY_AT_STORE: P_PAY_AT_STORE,
      iconBank,
      scrollY,
      positionCheckoutAction: 0,
    }
  },

  watch: {
    isDeposit() {
      this.autoSelectPayment()
    },
  },

  methods: {
    validate(success) {
      this.validateRecaptcha = success
    },

    onSubmit() {
      if (!this.loading) this.$emit('onSubmit')
    },

    onSelectPaymentMethod(event) {
      this.$store.commit('paymentType/setPaymentId', event.id)
      this.$emit('onSelectPaymentMethod', event.id)

      let plan = event.dropdown ? JSON.parse(event.dropdown) : null
      if (plan) {
        this.$store.commit(
          'paymentType/setPaymentInstallmentPlan',
          JSON.parse(event.dropdown)?.[0],
        )
      } else {
        this.$store.commit(
          'paymentType/setPaymentInstallmentPlan',
          null,
        )
      }

      this.checkSummary()
      this.$forceUpdate()
    },

    goBack() {
      this.$store.commit('cart/resetCouponCode')
      let backToPage =
        this.additionalProduct.length > 0
          ? 'CustomerAlsoBought'
          : 'ProductDetail'

      this.$router
        .push({
          name: backToPage,
          params: { slug: this.$route.params?.slug },
        })
        .catch(() => true)
    },

    autoSelectPayment() {
      if (this.isDeposit) {
        this.onSelectPaymentMethod(
          this.paymentTypeData?.filter(i => i.type === 'deposit')?.[0]
            ?.id,
        )
      } else {
        if (this.isShowPayAtStoreMethod) {
          this.onSelectPaymentMethod(
            this.paymentTypeData?.filter(
              i => i.type === 'pay-at-store',
            )?.[0]?.id,
          )
        } else {
          this.onSelectPaymentMethod(
            this.paymentTypeData?.filter(
              i => i.type !== 'deposit',
            )?.[0]?.id,
          )
        }
      }
    },

    handleScroll() {
      const checkoutActionHeight = document.querySelector(
        '.checkout-action',
      )?.offsetHeight
      this.scrollY =
        window.scrollY + window.innerHeight - checkoutActionHeight
      this.positionCheckoutAction = this.$refs.beforeCheckoutAction?.offsetTop
    },

    handleUpdateInstallmentPlan(value) {
      this.$store.commit(
        'paymentType/setPaymentInstallmentPlan',
        value,
      )
    },
  },
}
</script>

<style lang="stylus">
.pointer
    cursor pointer
.payment
    background white
    margin-top 1rem
.p-layout
    display flex
    flex-direction column
    align-content center
    align-items center
.payment-content-2
    width 100%
    @media medium
        width 100%
        padding 0 1rem
    @media small
        width 100%
        padding 0 1rem
.payment-content
    background black
    color white
    width 80%
    padding 1rem
    @media medium
        width 100%
    @media small
        width 100%
.channel-payment
    display flex
    align-items center
    margin-top 1rem
    width 100%
    flex-wrap wrap
.btn-bnn-submit
    background $color-bnn
    border-radius 30px;
    text-align center
    box-shadow: 0px 4px 6px #00000029;
    padding 1rem 6rem
    max-width 350px
    font-weight bold
    cursor pointer
    @media small
      padding 0.6rem 6rem
    @media medium
      padding 1rem 6rem
.btn-bnn-submit.disabled
    background $color-gray-100
    opacity 0.7
    cursor not-allowed
.text-detail-payment-delivery
    font-size 1rem
    @media medium
        font-size 0.8rem
    @media small
        font-size 0.8rem
.isLoading
  cursor not-allowed !important
  opacity 0.6

.checkout-action
   margin-top: 16px
   position relative
   display: flex
   justify-content: center
   .action
      display flex
      flex-direction: column
      gap: 16px
      width: 350px
   @media tablet
    display: flex
    flex-direction: column
    width: calc(100%+72px)
    margin-left: -36px
    .action
        width: 100%
        margin: 1.5rem 0

.footer-margin
    margin-bottom: 60px;
@media tablet
  .footer-margin
    margin-bottom: 60px;

.payment-type-layout
    width 50%
    @media medium
        width 100%
    @media small
        width 100%
.chanel-payment-grid
  display: grid
  gap: 24px
  margin-bottom 32px
  grid-template-columns: repeat(2, 1fr)
  @media medium
     grid-template-columns: repeat(1, 1fr)
     gap: 24px 0
  @media small
     grid-template-columns: repeat(1, 1fr)
     gap: 16px 0


.btn-checkout-back
    display none
    @media tablet
        display flex
        justify-content: center
        display block



.btn-back-to-cus-also-bought
  font-weight: bold !important
  font-size: 0.875rem !important
  padding: 18.5px 0 !important
  border-color: $color-secondary !important

.text-back-to-cus-also-bought
  color: $color-secondary-text

.payment-label
  margin: 1rem 0

.summary
    display: none
    justify-content: space-between
    margin: 1rem 0
    font-size: 1.15rem
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    @media tablet
      display: block
    .count-number
      width: 50%
      color: $color-bnn-gray-medium-2
      @media tablet
         font-size: 0.875rem
    .price-text
      width 50%
      display: flex
      flex-wrap: nowrap
      flex-direction: row
      justify-content: flex-end
      @media tablet
         font-size: 0.875rem
      .price
        color: $color-text-price
        font-weight: bold
        width auto
        margin-left: 0.2rem

.price-summary-cart-3-lines
  display: flex
  justify-content: space-between
  width: 100%
  align-items: center
  font-size: 1 rem

.is-tablet
  display: none
  @media tablet
    display: block

.checkout-action-fixed
   @media tablet
      flex-direction: column
      position: fixed
      width: 100% !important
      bottom: 0
      left: 0
      margin-left: 0 !important
      height: auto
      box-shadow: 1px 1px 2px 1px $color-gray-200;
      z-index 51
      margin-left: 0 !important
      margin-right: 0 !important
      padding-top: 1rem
      background: #fff
</style>
